@import '../base/base.scss';

.manageStudents {
	select {
		height: 30px;
		color: $textDarkColor;
		border-color: $textDarkColor;
		margin-bottom: 20px;
	}
	.studentListWrapper {
		.studentBox {
			width: 20%;
			margin: 0 0 20px;
		}
		.groupWrap {
			display: flex;
			align-items: center;
			flex-direction: column;
			box-sizing: border-box;
			justify-content: center;
			border: 1px solid $themePrimaryColor;
			strong {
				font-size: 18px;
				margin-bottom: 5px;
			}
			span {
				font-size: 16px;
			}
		}
	}
}

.row.checkboxWrapper {
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
	.checkBox {
		display: inline-flex;
		align-items: center;
		width: 25%;
		margin: 10px 0;
		font-size: 14px;
		input {
			margin-right: 5px;
		}
	}
}
