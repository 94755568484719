@import '~css/base/base.scss';

#parentRoutinePopup {
	.popup {
		max-width: 850px;
	}
	.parentTipSection {
		margin-top: 0px;
		.tipSec .row {
			& > div {
				width: calc(100% - 200px);
			}
			img,
			iframe {
				width: 200px;
			}
		}
		.stepList {
			padding: 0;
			margin-top: 20px;
			margin-bottom: 0;
			margin-left: 25px;
			display: inline-flex;
			list-style-type: none;
			li {
				border-top-left-radius: 15px;
				border-top-right-radius: 15px;
				background-color: $lightbackground;
				button {
					width: 60px;
					height: 30px;
					font-size: 16px;
					cursor: pointer;
					font-weight: bold;
					color: $textDarkColor;
				}
				&.active {
					background-color: $white-gray;
				}
			}
		}
		.stepButtonWrapper {
			padding: 10px;
			border-radius: 15px;
			background-color: $white;
			border: 10px solid $white-gray;
			p {
				margin-bottom: 0;
			}
			.inlineBtn {
				margin-top: 10px;
			}
		}
	}
}
