@import '~css/base/base.scss';

.weekplannerContainer {
	padding: 12px;
	border-radius: 15px;
	background-color: $white;
	.weekplannerHeader {
		display: flex;
		font-size: 14px;
		margin-top: 10px;
		padding-left: 20px;
		align-items: center;
		justify-content: space-between;
		& > div {
			display: flex;
			align-items: center;
		}
		.weekTabs {
			padding: 0;
			display: flex;
			margin: 0 20px;
			list-style-type: none;
			li {
				background-color: $themeGrayColor;
				a {
					color: $textDarkColor;
					padding: 10px 15px !important;
				}
				&.activeTab {
					background-color: $generic;
					a {
						color: $white;
					}
				}
			}
		}
		.weekButtons {
			display: flex;
			align-items: center;
			button {
				padding: 10px;
				display: flex;
				cursor: pointer;
				align-items: center;
			}
			span {
				padding: 5px 10px;
				border-radius: 4px;
				border: 1px solid $themeGrayColor;
			}
		}
		select {
			height: 30px;
			margin-right: 20px;
			color: $textDarkColor;
			border-color: $textDarkColor;
		}
	}
	.generic_wrapper {
		width: 100%;
		.pageContainer {
			padding: 12px;
			display: flex;
			flex-direction: column;
		}
		& .pageWrapper {
			flex: 1;
			padding: 12px;
			border-radius: 15px;
			min-height: initial;
			background-color: $white;
			&:not(:last-child) {
				margin-bottom: 12px;
			}
			.headingStyle {
				font-size: 18px;
				margin-bottom: 10px;
			}
			.columnWrapper {
				display: flex;
				justify-content: space-between;
				.column {
					width: calc(25% - 15px);
					padding: 5px;
					border-radius: 10px;
					section {
						padding: 5px;
						border-radius: 10px;
						background-color: $white;
						.lessonRow {
							display: flex;
							margin-bottom: 10px;
							align-items: center;
							color: $textDarkColor;
							justify-content: space-between;
							img {
								width: 40px;
								height: 40px;
								border-radius: 5px;
							}

							h3 {
								font-size: 14px;
								margin-left: 10px;
								margin-bottom: 0px;
							}
							.lessonWrapper {
								display: flex;
								align-items: center;
							}
							.buttonsWrapper {
								display: flex;
								button {
									padding: 3px;
									cursor: pointer;
								}
							}
						}
						.studentRowContainer,
						.addButton {
							width: 100%;
							&:last-child {
								.studentWrap {
									margin-bottom: 0;
								}
							}
						}
						.addButton {
							cursor: pointer;
							padding: 10px 0;
							border-radius: 8px;
							background-color: $light-gray;
							svg {
								opacity: 0.6;
								fill: $textDarkColor;
								transition: all 0.2s linear;
							}
							&:hover {
								background-color: $themeGrayColor;
								svg {
									opacity: 1;
									fill: $themePrimaryColor;
								}
							}
						}
					}
				}
			}
		}
	}
	.lessonColumnWrapper {
		.pageContainer {
			padding-bottom: 2px;
			min-height: initial;
			& > div {
				display: flex;
				flex-wrap: wrap;
				align-items: flex-start;
				.studentWrap {
					flex: 0 0 24%;
					margin-right: 10px;
					margin-bottom: 10px;
				}
			}
		}
	}
}
