/* all global styles can come here */
@import './css/base/extends.scss';
@import './css/partials/mixins';
@import './css/partials/theme';

@import './css/partials/reset.scss';
@import './css/partials/default.scss';

body {
	background-color: #fff;
	font-weight: 300;
	font-family: 'Roboto', sans-serif;
	color: $textColor;
}

.mainWrapper {
	width: 100%;
	position: relative;
	background: #fff;
	margin-left: 0;
	@include transition(all 0.2s linear);
	&.active {
		margin-left: 270px;
	}
}

.mainContainer {
	background: $themeGrayColor;
	padding: 20px;
	box-sizing: border-box;
	min-height: calc(100vh - 54px);
	@include border-top-radius(20px);
	.pageContainer {
		padding: 20px;
		background: white;
		border-radius: 20px;
		box-sizing: border-box;
		min-height: calc(100vh - 94px);
	}
}

.seprator {
	width: 100%;
	height: 2px;
	margin: 20px 0;
	background-color: #efefef;
	clear: both;
	float: none;
}

.innerContainer {
	width: 100%;
	max-width: 1200px;
	margin: 0px auto;
	box-sizing: border-box;
}

.inlineContainer {
	display: inline-block;
	vertical-align: top;
	position: relative;
	width: 100%;
}

.tableView {
	display: table;
	width: 100%;
	height: 100%;
	position: relative;
}
.tableCellView {
	display: table-cell;
	vertical-align: middle;
	width: 100%;
	height: 100%;
}

.headingStyle {
	color: $headingcolor;
	font-size: 22px;
	line-height: 32px;
	font-weight: 400;
	display: flex;
	align-items: center;
}

.sectionTitle {
	font-size: 18px;
	font-weight: 400;
	color: $headingcolor;
}

a {
	display: block;
	cursor: pointer;
	color: $textColor;
	text-decoration: none;
}

p {
	color: $textColor;
}

p {
	a,
	strong {
		display: inline-block;
		vertical-align: top;
		margin: 0 2px;
	}
	a {
		color: $blueColor;
		&:hover {
			color: $blueColor;
		}
	}
}

.defaultBtn {
	@extend %buttons;
}
.inlineBtn {
	@extend %buttons;
	display: inline-block;
	vertical-align: top;
	margin-top: 15px;
}
.outlineButton {
	@extend %buttons;
	padding: 0 15px;
	background: transparent;
	color: $textDarkColor;
	border-color: $textDarkColor;
	&.warningTheme {
		color: red;
		border-color: red;
	}
}

.pillButton {
	@extend %buttons;
	color: $themeGrayColor;
	background: $themePrimaryColor;

	&.small {
		padding: 0.25rem 0.5rem;
		font-size: smaller;
	}

	&.danger {
		color: $white;
		background: $taal;
		border-color: $white;

		&:hover {
			background: $white;
			color: $taal;
			border-color: $taal;
		}
	}
}
a.pillButton {
	color: $themeGrayColor;
	text-decoration: none;
}

input:-webkit-autofill {
	-webkit-box-shadow: 0 0 0 50px white inset;
	-webkit-text-fill-color: #333;
}
input:-webkit-autofill:focus {
	-webkit-box-shadow: 0 0 0 50px white inset;
	-webkit-text-fill-color: #333;
}
.noSelect,
img {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

input,
select,
textarea,
button {
	@include box-sizing;
	vertical-align: top;
	outline: none;
	color: $textColor;
	font-size: 14px;
	font-weight: 300;
	background: transparent;
	margin: 0;
	padding: 0;
	@include placeholder {
		color: #dddfdf;
	}
}

.subheading {
	color: $textColor;
	font-size: 25px;
	position: relative;
	font-weight: bold;
	margin-bottom: 20px;
}

.subsection {
	padding: 20px;
	background: #fff;
	margin-bottom: 20px;
}

.clearfix {
	@include clearfix();
}

.rowSec {
	box-sizing: border-box;
	margin: 0 -15px;
}

.inlineBlock {
	display: inline-block;
	vertical-align: top;
}

// page change transition effect
.fade-appear,
.fade-enter {
	opacity: 0;
}

.fade-appear-active,
.fade-enter-active {
	transition: opacity 0.3s linear;
	opacity: 1;
}

.fade-exit {
	transition: opacity 0.2s linear;
	opacity: 1;
}

.fade-exit-active {
	opacity: 0;
}

.infoButton {
	margin-left: 10px;
	margin-top: 5px;
	cursor: pointer;
	display: none;
	svg {
		width: 15px;
		height: 15px;
		transition: all 0.2s linear;
	}
	&.dark {
		svg {
			fill: #333;
		}
	}
	&.light {
		svg {
			fill: #fff;
		}
	}
	&:hover {
		svg {
			fill: #00b1ff;
		}
	}
}

.popupWrapper {
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 3;
	top: 0;
	left: 0;
	overflow: auto;
	opacity: 0;
	visibility: hidden;
	.popup {
		width: 95%;
		max-width: 560px;
		padding: 20px;
		background: #fff;
		position: relative;
		z-index: 2;
		border-radius: 10px;
		margin: 80px auto;
	}
	.overlayBackground {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
		background: rgba(black, 0.7);
	}
	&.videoPopup {
		.titleRow {
			margin-bottom: 10px;
			color: $textDarkColor;
		}
	}
}

.confirmationPopup,
.errorPopup {
	p {
		font-size: 16px;
		line-height: 1.6;
		margin-bottom: 15px;
	}
	.row {
		display: flex;
		margin-top: 20px;
		justify-content: space-between;
	}
	.success {
		margin-top: 20px;
		margin-bottom: 0;
		color: $themePrimaryColor;
	}
}

.qrPrintWrapper {
	display: none;
	@media print {
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: space-between;
		.lessondetails {
			display: flex;
			margin-left: 20;
			align-items: center;
			img {
				margin-right: 20px;
			}
			div {
				display: flex;
				flex: 1;
				flex-direction: column;
			}
			h1,
			h2 {
				font-size: 32px;
				font-weight: 400;
				line-height: 32px;
				color: $headingcolor;
			}
			h2 {
				font-size: 22px;
				margin-top: 30px;
			}
		}
		.logo {
			width: 200px;
		}
	}
}

// Define vars we'll be using for loader animation
$brand-success: $themePrimaryColor;
$loader-size: 2em;
$check-height: 18px;
$check-width: 7px;
$check-thickness: 3px;
$check-color: $brand-success;

.circleLoader {
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-left-color: $check-color;
	animation: loader-spin 1.2s infinite linear;
	position: relative;
	display: inline-block;
	vertical-align: top;
	border-radius: 50%;
	width: $loader-size;
	height: $loader-size;
}

.loadComplete {
	-webkit-animation: none;
	animation: none;
	border-color: $check-color;
	transition: border 500ms ease-out;
}

.checkmark {
	position: absolute;
	top: 18px;
	left: 5px;
	&.draw:after {
		animation-duration: 800ms;
		animation-timing-function: ease;
		animation-name: checkmark;
		transform: scaleX(-1) rotate(135deg);
	}

	&:after {
		content: '';
		opacity: 1;
		height: $check-height;
		width: $check-width;
		transform-origin: top left;
		border-right: $check-thickness solid $check-color;
		border-top: $check-thickness solid $check-color;
		position: absolute;
	}
}

@keyframes loader-spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes checkmark {
	0% {
		height: 0;
		width: 0;
		opacity: 1;
	}
	20% {
		height: 0;
		width: $check-width;
		opacity: 1;
	}
	40% {
		height: $check-height;
		width: $check-width;
		opacity: 1;
	}
	100% {
		height: $check-height;
		width: $check-width;
		opacity: 1;
	}
}

.tabsStyle {
	padding: 0;
	margin: 0 0 0;
	list-style-type: none;
	display: flex;
	margin-left: 40px;
	li {
		@include border-top-radius(15px);
		align-items: center;
		justify-content: center;
		display: flex;
		button,
		a {
			color: white;
			cursor: pointer;
			font-size: 14px;
			padding: 10px 25px;
		}
		&.taal_tab {
			background: $taal;
		}
		&.rekenen_tab {
			background: $rekenen;
		}
		&.motoriek_tab {
			background: $motoriek;
		}
		&.grote-motoriek_tab {
			background: $grote-motoriek;
		}
		&.generic_tab {
			margin-left: 10px;
			background: $generic;
		}
		&.behind_tab {
			margin-left: 10px;
			background: $orangeColor;
		}
		&.ahead_tab {
			background: $blueColor;
		}
		&.lichaam_tab {
			background: $lichaam;
		}
		&.wereld-om-ons-heen_tab {
			background: $wereld-om-ons-heen;
		}
		&.natuur_tab {
			background: $natuur;
		}
		&.tijd_tab {
			background: $tijd;
		}
		&.natuurverschijnselen_tab {
			background: $natuurverschijnselen;
		}
		&.ontdekken_tab {
			background: $ontdekken;
		}
		&.lightbackground_tab {
			background: $lightbackground;
		}
	}
}

.tabBorderStyle {
	&.taal_wrapper {
		.pageContainer {
			background: $taal;
		}
	}
	&.rekenen_wrapper {
		.pageContainer {
			background: $rekenen;
		}
	}
	&.motoriek_wrapper {
		.pageContainer {
			background: $motoriek;
		}
	}
	&.grote-motoriek_wrapper {
		.pageContainer {
			background: $grote-motoriek;
		}
	}
	&.generic_wrapper {
		& > .pageContainer {
			background: $generic;
		}
	}
	&.ahead_wrapper {
		.pageContainer {
			background: $blueColor;
		}
	}
	&.behind_wrapper {
		.pageContainer {
			background: $orangeColor;
		}
	}
	&.ontdekken_wrapper {
		.pageContainer {
			background: $ontdekken;
		}
	}
	&.natuurverschijnselen_wrapper {
		.pageContainer {
			background: $natuurverschijnselen;
		}
	}
	&.tijd_wrapper {
		.pageContainer {
			background: $tijd;
		}
	}
	&.natuur_wrapper {
		.pageContainer {
			background: $natuur;
		}
	}
	&.wereld-om-ons-heen_wrapper {
		.pageContainer {
			background: $wereld-om-ons-heen;
		}
	}
	&.lichaam_wrapper {
		.pageContainer {
			background: $lichaam;
		}
	}
}

.pagenotfound {
	display: flex;
	flex: 1;
	justify-content: center;
	align-items: center;
	img {
		width: 90%;
		max-width: 600px;
		margin: 0 auto;
	}
}

.flexBlock {
	display: flex;
}

.flexCenter {
	display: flex;
	align-items: center;
	justify-content: center;
}

.flexSpaceCenter {
	align-items: center;
	justify-content: space-between;
	.groupLink {
		font-size: 14px;
		margin-right: 20px;
		color: $textDarkColor;
		text-decoration: underline;
		transition: all 0.2s linear;
		&:hover {
			color: $themePrimaryColor;
		}
	}
}

// when conflict accept changes from academy/incoming branch
.generic {
	background: $generic !important;
}

.taal {
	background: $taal !important;
}

.rekenen {
	background: $rekenen !important;
}

.motoriek {
	background: $motoriek !important;
}

.grote-motoriek {
	background: $grote-motoriek !important;
}

.ontdekken {
	background: $ontdekken !important;
}

.natuurverschijnselen {
	background: $natuurverschijnselen !important;
}

.tijd {
	background: $tijd !important;
}

.natuur {
	background: $natuur !important;
}

.wereld-om-ons-heen {
	background: $wereld-om-ons-heen !important;
}

.lichaam {
	background: $lichaam !important;
}

.behind_tab {
	background: $orangeColor !important;
}

.ahead_tab {
	background: $blueColor !important;
}

@keyframes flickerAnimation {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@-o-keyframes flickerAnimation {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@-moz-keyframes flickerAnimation {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@-webkit-keyframes flickerAnimation {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
