@import '../base/base.scss';

$marginBottom: 14px;
$sectionPedding: 0 10px;

button {
	outline: none;
	border: 0;
}

.studentResultsWrapper .pageContainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-content: flex-start;
}

.nodataWrapper {
	flex: 1;
	padding: 15px;
	display: flex;
	flex-direction: column;
	border-radius: 15px;
	background: white;
	box-sizing: border-box;
	min-height: calc(100vh - 135px);
	p {
		margin-top: 20px;
		max-width: 600px;
	}
}

.studentIntroSec {
	display: flex;
	// flex-direction: column;
	align-items: center;
	padding: 15px;
	width: 100%;
	box-sizing: border-box;
	background: white;
	border-radius: 15px;
	img {
		width: 80px;
		height: 80px;
		object-fit: cover;
		margin-right: 15px;
		border-radius: 15px;
		// border-radius: 50%;
	}
	& > div {
		// text-align: center;
		// margin-top: 20px;
		h1 {
			color: $headingcolor;
			font-size: 20px;
		}
		span {
			display: inline-flex;
			margin-top: 10px;
		}
	}
}

.overviewLesson {
	display: flex;
	flex-direction: column;
	width: calc(50% - 7px);
	padding: 15px 19px 15px 15px;
	margin-top: $marginBottom;
	box-sizing: border-box;
	background: white;
	position: relative;
	border-radius: 15px;
	overflow: hidden;
	h2 {
		display: flex;
		margin-bottom: 25px;
		align-items: flex-start;
		justify-content: space-between;
		a {
			font-size: 20px;
			color: $headingcolor;
			display: inline-flex;
		}
		.iconWrap {
			display: flex;
			.eye {
				width: 18px;
				height: 18px;
			}
			svg {
				width: 15px;
				height: 15px;
				margin-left: 4px;
			}
		}
	}
	.flexWrapper {
		img {
			width: 100px;
			object-fit: cover;
			margin-right: 10px;
		}
		.sliderWrapper {
			flex: 1;
			padding-right: 10px;
			.steps {
				font-weight: 600;
				margin-bottom: 12px;
				color: $textDarkColor;
				position: relative;
				display: flex;
				justify-content: space-between;
			}
			.sliderComponent {
				// .rc-slider-track {
				//   background: rgba($darkBgColor, 0.7);
				// }
				.rc-slider-handle {
					border-color: $darkBgColor;
					&:focus {
						box-shadow: 0 0 0 2px rgba($darkBgColor, 0.3);
					}
				}
			}
			p {
				margin-top: 15px;
				color: $textDarkColor;
			}
			button {
				margin-top: 15px;
				color: $textDarkColor;
				padding: 0 0 5px;
				border-bottom: 1px solid $darkBgColor;
				transition: all 0.2s linear;
				&:hover {
					cursor: pointer;
					color: $themePrimaryColor;
					border-color: $themePrimaryColor;
				}
			}
		}
		.graphWrapper {
			flex: 1;
		}
	}
}

.sliderComponent {
	.rc-slider-track,
	.rc-slider-rail,
	.rc-slider-step {
		height: 10px;
	}
	.rc-slider-handle {
		width: 20px;
		height: 20px;
	}
	.rc-slider-rail {
		background: #ccc;
	}
	&.ahead {
		.rc-slider-track {
			background: $blueColor;
		}
		.rc-slider-rail {
			background: lighten($blueColor, 25%);
		}
	}
	&.behind {
		.rc-slider-track {
			background: $orangeColor;
		}
		.rc-slider-rail {
			background: lighten($orangeColor, 25%);
		}
	}
	&.done {
		.rc-slider-track {
			background: $generic;
		}
		.rc-slider-rail {
			background: lighten($generic, 25%);
		}
	}
	&.ontrack {
		.rc-slider-track {
			background: $greenColor;
		}
		.rc-slider-rail {
			background: lighten($greenColor, 25%);
		}
	}
}

.studentResultPlaceHolder {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	.pageIntroSec_placeholder {
		flex: none;
		width: calc(50% - 5px);
		margin: 10px 0 0 0;
		box-sizing: border-box;
	}
}

.refreshWrapper {
	left: 0;
	right: 0;
	top: 50%;
	z-index: 2;
	margin: auto;
	height: 100px;
	position: fixed;
	max-width: 500px;
	border-radius: 25px;
	background: white;
	border: 20px solid black;
	transform: translateY(-50%);
	h4 {
		top: 50%;
		padding: 10px;
		font-size: 16px;
		color: black;
		text-align: center;
	}
	.outlineButton {
		left: 0;
		right: 0;
		margin: auto;
		color: #333;
		padding: 0 15px;
		position: absolute;
		border-color: #333;
		background: transparent;
	}
}
