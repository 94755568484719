@import '../../base/base.scss';
.breadCrumbMenu {
	display: flex;
	flex: 1;
	padding: 0 15px 15px;
	ul {
		margin: 0;
		padding: 0;
		display: flex;
		list-style-type: none;
		li {
			padding: 0 15px;
			font-size: 14px;
			position: relative;
			display: flex;
			a,
			button,
			span {
				color: $textDarkColor;
				margin-right: 10px;
				transition: all 0.2s linear;
			}
			a,
			button {
				&:hover {
					color: $themePrimaryColor;
				}
			}
			.active {
				color: $themePrimaryColor;
				font-weight: 600;
			}
			&:first-child {
				padding-left: 0;
			}
			&:not(:last-child):after {
				content: '\00bb';
				position: absolute;
				right: 0;
				top: 0;
			}
			button {
				-webkit-appearance: none;
				border: 0;
				cursor: pointer;
				color: $textDarkColor;
			}
			.availableOptions {
				a {
					display: inline-block;
					vertical-align: top;
				}
			}
			.defaultLink {
				text-transform: capitalize;
			}
		}
	}
}

.pagenotfound {
	display: flex !important;
	justify-content: center;
	align-items: center;
	img {
		max-width: 600px;
	}
}
