@import '~css/base/base.scss';

.loaderWrapper {
	width: 100%;
	height: 100%;
	display: flex;
	min-height: 300px;
	align-items: center;
	justify-content: center;
	background-color: white;
	& > div {
		max-width: 200px;
	}
}
