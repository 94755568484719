@import '../../base/base.scss';

.warningPopup {
	.popup {
		height: auto;
		overflow: auto;
		margin: 10px 0;
		line-height: 28px;
	}

	a {
		display: inline;
		color: $blueColor;
	}

	button {
		margin: 10px 10px 0;
		float: right;
	}
}
