@import '~css/base/base.scss';

.manageLessonsWrapper,
.routineLessonsWrapper {
	margin-top: -35px;
	.tabsStyle {
		margin-left: 0;
		margin-right: 40px;
		justify-content: flex-end;
	}
	.groupResultsWrapper {
		padding: 10px 20px 20px;
	}
}
.manageLessonsWrapper {
	.taal_wrapper .lesson {
		background: lighten($taal, 30%) !important;
		&.archived {
			background: lighten($taal, 40%) !important;
		}
	}
	.rekenen_wrapper .lesson {
		background: lighten($rekenen, 30%) !important;
		&.archived {
			background: lighten($rekenen, 42%) !important;
		}
	}
	.motoriek_wrapper .lesson {
		background: lighten($motoriek, 30%) !important;
		&.archived {
			background: lighten($motoriek, 43%) !important;
		}
	}
	.grote-motoriek_wrapper .lesson {
		background: lighten($grote-motoriek, 15%) !important;
		&.archived {
			background: lighten($grote-motoriek, 25%) !important;
		}
	}
}
