@import '../base/base.scss';

.mainContainer {
	.flexContainer {
		display: flex;

		.contentContainer {
			width: 65%;
			.socialWrapper {
				display: flex;
				align-items: flex-start;
				margin-top: 20px;
				background: #fff;
				padding: 30px 20px;
				border-radius: 15px;
				&:nth-of-type(1) {
					margin-top: 20px;
				}
				img {
					margin-right: 20px;
				}
				.socialContent {
					h2 {
						color: $textDarkColor;
						font-size: 18px;
					}
					ul {
						margin: 0;
						padding: 0;
						list-style-type: none;
						li {
							margin: 20px 0 0;
							div {
								display: flex;
								align-items: center;
								input {
									width: 15px;
									height: 15px;
								}
								label,
								span {
									width: 100%;
									margin-left: 5px;
									margin-right: 10px;
								}
								label {
									cursor: pointer;
								}
							}
						}
					}
				}
			}
		}

		.remarksContainer {
			width: 35%;
			position: relative;
			padding: 10% 0 0 2%;
			.quill {
				padding: 0;
				height: auto;
				padding: 10px;
				margin: 20px 0 0;
				min-height: 320px;
				border: 1px solid #999;
				p {
					margin: 0;
				}
				.ql-toolbar {
					display: none;
				}
				.ql-container.ql-snow {
					border: none;
				}
				.ql-editor.ql-blank::before {
					left: 0;
				}
				.ql-editor {
					padding: 0;
					font-size: 16px;
				}
			}
			.circleLoader {
				margin-top: 10px;
			}
			button {
				right: 0;
				margin-top: 20px;
				position: absolute;
			}
		}
	}
}
