@import '../base/base.scss';

$marginBottom: 30px;

.seasonWrapper,
.periodWrapper,
.topicsWrapper {
	border-radius: 20px;
	.pageContainer {
		padding: 0 !important;
	}
}

.seasonWrapper {
	padding: 20px;
	border-radius: 0;
	@include border-top-radius(20px);
}

.lente_Season {
	background: $lenteSeason;
}
.zomer_Season {
	background: $zomerSeason;
}
.winter_Season {
	background: $winterSeason;
}
.herfst_Season {
	background: $herfstSeason;
}

.periodWrapper {
	display: flex;
	align-items: flex-start;
	.periodsTab {
		width: 36px;
		margin: 40px 0;
		padding: 0;
		display: flex;
		align-items: flex-start;
		flex-direction: row-reverse;
		list-style-type: none;
		position: sticky;
		top: 40px;
		writing-mode: vertical-lr;
		transform: rotate(-180deg);
		li {
			opacity: 0.7;
			display: flex;
			align-items: center;
			background: white;
			justify-content: center;
			@include border-right-radius(15px);
			a {
				padding: 26px 8px;
				color: $textDarkColor;
			}
			&.active {
				opacity: 1;
			}
		}
	}
	& > section {
		background: white;
		width: 100%;
		box-sizing: border-box;
		min-height: calc(100vh - 94px);
		padding: 20px;
		border-radius: 20px;
	}
}

.topicsWrapper {
	.topicHeader {
		display: flex;
		align-items: flex-end;
		.periodImage {
			padding: 0 20px;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 160px;
			img {
				max-width: 160px;
			}
		}
		.topicLessonContainer {
			display: flex;
			flex: 1;
			flex-direction: column;
			.topicTabs {
				padding: 0;
				display: flex;
				font-size: 14px;
				margin: 0 0 0 40px;
				list-style-type: none;
				li {
					@include border-top-radius(15px);
					align-items: center;
					justify-content: center;
					display: flex;
					a {
						padding: 8px 20px;
						color: white;
					}
				}
				.taal_topic {
					background: $taal;
				}
				.rekenen_topic {
					background: $rekenen;
				}
				.motoriek_topic {
					background: $motoriek;
				}
				.grote-motoriek_topic {
					background: $grote-motoriek;
				}
				.ontdekken_topic {
				    background: $ontdekken;
				}
				.natuurverschijnselen_topic {
				    background: $natuurverschijnselen;
				}
				.tijd_topic {
				    background: $tijd;
				}
				.natuur_topic {
				    background: $natuur;
				}
				.wereld-om-ons-heen_topic {
				    background: $wereld-om-ons-heen;
				}
				.lichaam_topic {
				    background: $lichaam;
				}
				.lesmaterialen_topic {
					margin-left: 15px;
					background: $product;
					a {
						display: flex;
						align-items: center;
					}
					svg {
						width: auto;
						height: 18px;
						margin-left: 10px;
					}
				}
			}
		}
	}
	.topicContainer {
		padding: 20px;
		box-sizing: border-box;
		-webkit-border-radius: 20px;
		-webkit-border-top-right-radius: 0;
		-moz-border-radius: 20px;
		-moz-border-radius-topright: 0;
		border-radius: 20px;
		border-top-right-radius: 0;
		min-height: 400px;
		.noLesson {
			.lessonSection {
				width: 100%;
				padding: 20px;
				min-height: 320px;
				flex-direction: column;
				h2 {
					margin-bottom: 10px;
					&:not(:first-child) {
						margin-top: 20px;
					}
				}
				p {
					margin: 5px 0;
				}
			}
		}
	}
}
.taal_topic {
	& .topicContainer,
	.lessonsWrapper {
		background: $taal;
	}
}
.rekenen_topic {
	& .topicContainer,
	.lessonsWrapper {
		background: $rekenen;
	}
}
.motoriek_topic {
	& .topicContainer,
	.lessonsWrapper {
		background: $motoriek;
	}
}
.grote-motoriek_topic {
	& .topicContainer,
	.lessonsWrapper {
		background: $grote-motoriek;
	}
}
.ontdekken_topic {
	& .topicContainer,
	.lessonsWrapper {
		background: $ontdekken;
	}
}
.natuurverschijnselen_topic {
	& .topicContainer,
    .lessonsWrapper {
		background: $natuurverschijnselen;
	}
}
.tijd_topic {
	& .topicContainer,
    .lessonsWrapper {
	    background: $tijd;
	}
}
.natuur_topic {
	& .topicContainer,
    .lessonsWrapper {
	    background: $natuur;
	}
}
.wereld-om-ons-heen_topic {
	& .topicContainer,
    .lessonsWrapper {
	    background: $wereld-om-ons-heen;
	}
}
.lichaam_topic {
	& .topicContainer,
    .lessonsWrapper {
	    background: $lichaam;
	}
}
.lesmaterialen_topic {
	& .topicContainer,
	.lessonsWrapper {
		background: $product;
	}
	.lessonsWrapper {
		.lessonSection {
			padding-bottom: 60px;
			margin-bottom: 0 !important;
			&.flexWrapper {
				display: flex;
				flex-direction: column;
			}
		}
	}
}

.lessonsWrapper {
	padding: 15px 15px 0;
	@include border-top-radius(20px);
	justify-content: space-between;
}
.topicLessonContainer {
	.lessonsWrapper {
		align-items: normal;
		&.flexWrap {
			flex-wrap: wrap;
			.lessonSection {
				flex: none;
				margin: 0;
				width: calc(50% - 6px);
				&:first-child {
					width: 100%;
					margin-bottom: 12px;
				}
			}
		}
	}
}
.lessonSection {
	&:nth-of-type(even) {
		margin-left: 12px;
	}
	display: flex;
	flex: 1;
	padding: 10px;
	background: white;
	border-radius: 15px;
	margin: 0;
	box-sizing: border-box;
	img {
		align-self: baseline;
		max-width: 90px;
		margin-right: 10px;
	}
	.lessonWrapper {
		flex: 1;
	}
	h1 {
		margin-bottom: 10px;
		padding-right: 20px;
		position: relative;
		.infoButton {
			top: 0;
			right: 0;
			display: block;
			position: absolute;
		}
	}
	p {
		font-size: 16px;
		line-height: 26px;
		margin-bottom: 0;
	}
	.outlineButton {
		padding: 0 10px;
		margin-top: 10px;
		margin-right: 8px;
	}
	.doneButton {
		display: inline-flex;
		align-items: center;
		flex-direction: row-reverse;
		svg {
			height: 15px;
			margin-left: 8px;
			fill: $darkBgColor;
			position: relative;
			transition: all 0.2s linear;
		}
		&:hover {
			svg {
				fill: $themePrimaryColor;
			}
		}
	}
	.disableButton {
		color: #fff;
		background-color: $themePrimaryColor;
		border-color: $themePrimaryColor;
		cursor: pointer;
		pointer-events: all;
		display: inline-flex;
		align-items: center;
		flex-direction: row-reverse;
		svg {
			height: 15px;
			margin-left: 8px;
			position: relative;
			fill: #fff;
			transition: all 0.2s linear;
		}
		&:hover {
			color: #fff;
			background-color: $themePrimaryColor;
			svg {
				fill: #fff;
			}
		}
	}
	.subAnchors {
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		margin-top: 8px;
		a,
		button {
			color: $textDarkColor;
			font-size: 13px;
			text-decoration: underline;
			transition: color 0.2s linear;
			margin-top: 7px;
			cursor: pointer;
			&:hover {
				color: $themePrimaryColor;
			}
			&:not(:last-child) {
				margin-right: 12px;
			}
		}
	}
	.mainButtons {
		margin-top: 7px;
		button:last-child {
			margin-right: 0;
		}
		.outlineButton {
			margin-top: 8px;
		}
	}
}

.pageIntroSec_placeholder {
	&:nth-of-type(even) {
		margin-left: 12px;
	}
	display: flex;
	flex: 1;
	padding: 10px;
	background: white;
	border-radius: 15px;
	margin-bottom: 15px;
	& .textWrapper {
		flex: 1;
	}
	span {
		display: flex;
	}
	.image_placeholder {
		width: 90px;
		height: 90px;
		background: #f6f6f6;
		margin-right: 30px;
	}
	.subAnchors {
		display: flex;
		margin-top: 20px;
	}
}

.lessonLayout {
	.pageIntroSec_placeholder {
		flex: none;
		width: calc(50% - 5px);
		margin: 10px 0 0 0;
		box-sizing: border-box;
	}
}

.obsrBtnWrp {
	display: flex;
	justify-content: center;
}

.observationWrapper {
	& > .sectionTitle {
		margin-bottom: 20px;
		color: white;
	}
}

.developmentSteps {
	display: flex;
	align-items: normal;
	justify-content: space-between;
	.column {
		flex: none;
		width: 19.5%;
		padding: 10px 10px 15px;
		background: white;
		box-sizing: border-box;
		border-top-left-radius: 15px;
		border-top-right-radius: 15px;
		p {
			line-height: 1.6;
			margin-top: 4px;
		}
		.step {
			h2 {
				margin-bottom: 0;
				font-size: 16px;
			}
		}
	}
}

.groupLayout {
	@extend .developmentSteps;
	flex-direction: column;
	margin-bottom: $marginBottom;
	.flexWrapper {
		align-items: normal;
		justify-content: space-between;
	}
	.sectionTitle {
		color: white;
		margin-bottom: 15px;
	}
	.column {
		flex: none;
		width: 19.5%;
		padding: 5px 5px 0;
		box-sizing: border-box;
		transition: all 0.2s linear;
		background: white;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
		border-bottom-right-radius: 15px;
		border-bottom-left-radius: 15px;
	}
}

.lessonLayout {
	@extend .groupLayout;
	margin-bottom: 0;

	.flexWrapper {
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.lessonSection {
		flex: none;
		width: calc(50% - 5px);
		margin: 10px 0 0 0;
		box-sizing: border-box;
		.oudersButton {
			margin-top: 8px;
		}
	}

	.sectionTitle {
		margin: 0 0 10px;
	}
}

.routeenLessonPlan {
	margin: 20px 0 0;
	.groupLayout {
		margin-bottom: 0;
	}
}

$class-slug: percent- !default;

.draggableWrapper {
	position: relative;
	.studentWrap {
		span.name {
			padding-right: 55px;
			@media (max-width: 1450px) {
				width: 58%;
				min-width: 0;
				font-size: 14px;
				max-width: 82px;
				padding-right: 0px;
			}
		}
		.icon {
			width: 15px;
		}
	}
	.iconWrap {
		top: 50%;
		z-index: 2;
		right: 10px;
		padding: 0 10px;
		position: absolute;
		transform: translateY(-50%);
		cursor: pointer;
		svg {
			margin-left: 3px;
			fill: #333;
			transition: fill 0.2s linear;
			&.eyeclose {
				fill: #aeaeae;
			}
			&:hover {
				fill: $themePrimaryColor;
			}
		}
	}
}

.refreshWrapper {
	left: 0;
	right: 0;
	top: 50%;
	z-index: 2;
	margin: auto;
	height: 100px;
	position: fixed;
	max-width: 500px;
	border-radius: 25px;
	background: white;
	border: 20px solid black;
	transform: translateY(-50%);
	h4 {
		top: 50%;
		padding: 10px;
		font-size: 16px;
		color: black;
		text-align: center;
	}
	.outlineButton {
		left: 0;
		right: 0;
		margin: auto;
		color: #333;
		padding: 0 15px;
		position: absolute;
		border-color: #333;
		background: transparent;
	}
}

.noteWrapper {
	padding: 20px;
	min-height: 360px;
	line-height: 26px;
	border-radius: 15px;
	color: $textDarkColor;
	box-sizing: border-box;
	background-color: white;
}
