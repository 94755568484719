html,
body,
div,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
pre,
del,
dfn,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
i,
dl,
dt,
dd,
fieldset,
form,
label,
legend,
caption,
img,
a,
p {
  margin: 0px;
  padding: 0px;
  outline: 0px;
  border: 0px;
  display: block;
}

html,
body {
  width: 100%;
  height: 100%;
}
