@import "../base/base.scss";

.contentSec {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  h2,
  h1 {
    font-size: 22px;
    margin-bottom: 15px;
    color: $headingcolor;
  }
  h3 {
    font-size: 24px;
    margin-bottom: 15px;
    color: $headingcolor;
  }
  p,
  & > div {
    color: $textColor;
    line-height: 24px;
    margin: 5px 0;
    a {
      color: $blueColor;
      display: inline-block;
      vertical-align: top;
    }
    a.readmorebtn {
      background: none;
      color: #1588c2;
      border: none;
      text-decoration: underline;
      padding: 0;
      line-height: 30px;
      &:hover {
        text-decoration: none;
        border: 0;
      }
    }
  }
}
