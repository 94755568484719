@import '../../base/base.scss';

.LessonAddEditWrapper {
	&.popupWrapper .popup {
		max-width: 980px;
		input:read-only {
			cursor: not-allowed;
			border-color: #eaeaea;
			background-color: #eaeaea;
		}
	}
}

.popupTabsWrapper {
	padding: 0;
	display: flex;
	overflow: hidden;
	border-radius: 4px;
	list-style-type: none;
	li {
		flex: 1;
		height: 40px;
		display: flex;
		cursor: pointer;
		align-items: center;
		justify-content: center;
		transition: all 0.2s linear;
		background-color: rgba($textDarkColor, 0.2);
		&.active {
			color: white;
			background-color: rgba($textDarkColor, 0.8);
		}
		&:hover {
			color: white;
			background-color: rgba($textDarkColor, 0.8);
		}
	}
}

.stepsWrapper {
	display: none;
	&.step_0 {
		display: block;
	}
	.subLabel {
		font-size: 14px;
		font-weight: normal;
	}
	textarea.inputField {
		padding: 10px;
		height: 100px;
		line-height: 1.4;
	}
	.inputWrapper {
		flex: 1;
		margin-right: 15px;
	}
	button:disabled:hover {
		color: white;
	}
	.clearButton {
		margin-left: 10px;
		align-items: center;
		display: inline-flex;
		justify-content: center;
		&:disabled {
			svg {
				fill: white;
			}
		}
	}
	.translateButton {
		transform: translateY(27px);
	}
	.disableButton {
		cursor: not-allowed;
		pointer-events: none;
		filter: grayscale(1);
	}
}
.btnWrapper {
	height: 36px;
	display: flex;
	overflow: hidden;
	margin-right: 15px;
	border-radius: 4px;
	align-items: center;
	justify-content: center;
	border: 1px solid #ddd;
	button {
		height: 100%;
		cursor: pointer;
		padding: 0px 12px;
		&.active {
			color: white;
			background-color: $themePrimaryColor;
		}
	}
}

input[type='url']:invalid {
	border-color: red;
}
