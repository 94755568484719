@import 'src/css/base/base.scss';

.topicContainer div.productWrapper.pageContainer {
	min-height: calc(100vh - 340px);
}

div.productWrapper {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	&.pageContainer {
		background: white;
		border-radius: 20px;
		box-sizing: border-box;
		min-height: calc(100vh - 94px);
		padding: 5px 10px 15px 10px !important;
	}
	.itemBox {
		width: 33.33%;
		display: flex;
		padding: 0 10px;
		margin-top: 20px;
		position: relative;
		align-items: center;
		box-sizing: border-box;
		flex-direction: column;
		a {
			width: 100%;
		}
		.aspectContainer {
			width: 100%;
			padding-top: 70%;
			position: relative;
			img {
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				background: $white;
				position: absolute;
			}
			.pricebox {
				right: 0;
				z-index: 2;
				bottom: 30px;
				display: flex;
				padding: 5px 20px;
				position: absolute;
				align-items: center;
				color: $textDarkColor;
				flex-direction: column;
				justify-content: center;
				background: $lenteSeason;
				span {
					font-size: 12px;
				}
				&:after {
					content: '';
					width: 0;
					right: 0;
					height: 0;
					top: 100%;
					filter: blur(1px);
					position: absolute;
					border-left: 100px solid transparent;
					border-top: 10px solid rgba(0, 0, 0, 0.4);
				}
			}
		}
		strong {
			color: #333;
			display: block;
			margin: 10px 0 5px;
			text-align: center;
		}
		.description {
			display: block;
			font-size: 14px;
			line-height: 1.5;
			text-align: center;
		}
	}
	.detailWrapper {
		padding-top: 20px;
		width: 100%;
		.detailHeader {
			display: flex;
			position: relative;
			margin-bottom: 15px;
			img {
				min-height: 200px;
				margin-right: 15px;
			}
			.itemIntro {
				.headingStyle {
					margin-bottom: 15px;
				}
				p {
					margin-bottom: 10px;
				}
			}
		}

		p {
			line-height: 26px;
		}
		.backBtn {
			top: 0;
			padding: 0;
			right: 15px;
			border: 0px;
			outline: none;
			color: #333;
			cursor: pointer;
			font-size: 14px;
			position: absolute;
			text-decoration: underline;
		}
	}
}
