@import '~css/base/base.scss';

// .routineLessonsWrapper {
// 	margin-top: -35px;
// 	.tabsStyle {
// 		margin-left: 0;
// 		margin-right: 40px;
// 		justify-content: flex-end;
// 	}
// 	.groupResultsWrapper {
// 		padding: 10px 20px 20px;
// 	}
// }

.routineLessonsWrapper {
	.period {
    .period-actions {
        display: flex;
        align-items: center;
        column-gap: 16px;
        .accordion-arrow {
          width: 20px;
          height: 20px;
        }
    }
    .accordion-arrow {
      // transition: transform 0.2s ease-out;
      transform: rotate(180deg);
      &--active {
        transform: rotate(360deg) !important;
      }
    }
	}
}
