// @font-face {
//   font-family: SansBold;
//   src: url("../../fonts/Avenir.otf");
// }

// @font-face {
//   font-family: SansRegular;
//   src: url("../../fonts/OpenSans-Regular.ttf");
// }

$font_stack: 'Roboto', sans-serif;

body {
	font-family: $font_stack;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	display: block;
	font-weight: 600;
}

input,
select,
textarea,
button {
	font-family: $font_stack;
}

//same sections
.textScreen {
	padding-bottom: 50px;

	p {
		margin: 10px 0;
		line-height: 22px;
	}
}

p {
	i,
	em {
		display: inline-block;
	}
}

.aspectContainer {
	width: 100%;
	padding-top: 100%;
	position: relative;
}
