@import '~css/base/base.scss';

div.imageEditorPopup {
	z-index: 5;
	opacity: 1;
	display: flex;
	flex-flow: wrap;
	visibility: visible;
	align-items: center;
	justify-content: center;
	.popup {
		width: initial;
		.row {
			margin-bottom: 20px;
		}
		.rotateIcon {
			display: flex;
			align-items: center;
			svg {
				width: 15px;
				height: auto;
				margin-right: 8px;
				transition: fill 0.2s linear;
			}
			&:hover {
				svg {
					fill: $themePrimaryColor;
				}
			}
		}
	}
}
