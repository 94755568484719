@import '~css/base/base.scss';

.studentWrapper {
	display: flex;
	align-items: flex-start;
	color: $textDarkColor;
	position: relative;
	flex-direction: column;
	.studentIntro {
		display: flex;
		h3 {
			font-size: 18px;
		}
		span {
			font-size: 14px;
			margin-top: 5px;
			display: inline-block;
		}
	}
	img {
		width: 70px;
		height: 70px;
		margin-right: 20px;
		border-radius: 10px;
		object-fit: cover;
		background-color: lighten(gray, 46%);
	}
	.sliderWrapper {
		flex: 1;
		width: 100%;
		padding: 0 10px;
		box-sizing: border-box;
		margin-bottom: 15px;
		.steps {
			font-weight: 600;
			margin-bottom: 12px;
			color: $textDarkColor;
			position: relative;
			display: flex;
			justify-content: space-between;
		}
		.sliderComponent {
			.rc-slider-handle {
				border-color: $darkBgColor;
				&:focus {
					box-shadow: 0 0 0 2px rgba($darkBgColor, 0.3);
				}
			}
		}
	}
	.sliderComponent {
		.rc-slider-track,
		.rc-slider-rail,
		.rc-slider-step {
			height: 10px;
		}
		.rc-slider-handle {
			width: 20px;
			height: 20px;
		}
		.rc-slider-rail {
			background: #ccc;
		}
		&.ahead {
			.rc-slider-track {
				background: $blueColor;
			}
			.rc-slider-rail {
				background: lighten($blueColor, 25%);
			}
		}
		&.behind {
			.rc-slider-track {
				background: $orangeColor;
			}
			.rc-slider-rail {
				background: lighten($orangeColor, 25%);
			}
		}
		&.done {
			.rc-slider-track {
				background: $generic;
			}
			.rc-slider-rail {
				background: lighten($generic, 25%);
			}
		}
		&.ontrack {
			.rc-slider-track {
				background: $greenColor;
			}
			.rc-slider-rail {
				background: lighten($greenColor, 25%);
			}
		}
	}

	.studentDetail {
		flex: 1;
		width: 100%;
		strong {
			font-size: 18px;
			margin-top: 15px;
		}
		p {
			font-size: 14px;
			margin-top: 5px;
		}
		textarea {
			width: 100%;
			margin: 20px 0;
			padding: 10px;
			border-radius: 5px;
			height: 120px;
		}
		.btnsWrap {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			button {
				margin-left: 20px;
			}
		}
	}
}

.bulletPoint {
	top: 1px;
	width: 9px;
	text-align: center;
	position: relative;
	transform: scale(2.5);
}
