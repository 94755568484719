@import '../base/base.scss';

.profileWrapper {
	margin-top: 20px;
	max-width: 700px;
	label {
		margin-bottom: 10px;
		font-weight: bold;
		color: $textDarkColor;
	}
	.inputField {
		height: 40px;
		line-height: 40px;
		padding: 0 10px;
		width: 100%;
		margin-bottom: 20px;
		background: white;
		border-radius: 5px;
		border: 1px solid #ddd;
	}
	.error {
		color: red;
		margin-top: 20px;
	}
	.row {
		margin-top: 20px;
		display: flex;
	}
	.pictureWrapper {
		justify-content: space-between;
		.buttonWrapper {
			display: flex;
			align-items: center;
			flex-direction: column;
			.deleteBtn {
				color: $taal;
				margin: 10px 0;
				border: 1px solid $taal;
				background-color: $white;
				&:hover {
					color: $white;
					background-color: $taal;
				}
			}
		}
		.profilePicture {
			width: 150px;
			height: 150px;
			margin-top: -30px;
			position: relative;
			object-fit: cover;
			&.removeMargin {
				margin-top: initial;
			}
		}
	}
	button {
		margin-right: 20px;
	}
	.nameRow {
		display: flex;
		input {
			&:nth-child(even) {
				margin: 0 10px;
				max-width: 120px;
			}
		}
	}
}
.success {
	color: green;
	margin-top: 20px;
}

.formWrapper {
	@extend .profileWrapper;
	.textareaField {
		padding: 0 10px;
		width: 100%;
		height: 100px;
		max-width: 600px;
		background: white;
		border-radius: 5px;
		border: 1px solid #ddd;
	}
}
