@import '~css/base/base.scss';

.historyWrapper {
	display: flex;
	flex-direction: column;
	position: relative;
	color: $textDarkColor;
	.closePopup {
		position: absolute;
		top: -50px;
		right: 0px;
		z-index: 2;
		cursor: pointer;
		background: transparent;
		svg {
			transition: all 0.2s linear;
			fill: white;
		}
		&:hover {
			svg {
				fill: $themePrimaryColor;
			}
		}
	}
	.studentWrap {
		display: flex;
		border-radius: 0;
		background: white;
		align-items: flex-start;
		img {
			width: 100px;
			height: 100px;
			margin-right: 10px;
			object-fit: cover;
		}
		& > div {
			h3 {
				color: $headingcolor;
				font-size: 18px;
				line-height: 1.4;
				padding-right: 30px;
			}
			span,
			p {
				margin-top: 10px;
				display: flex;
			}
		}
	}

	.stepHistory {
		display: flex;
		flex: 1;
		margin-top: 10px;
		padding: 10px 15px;
		color: $textDarkColor;
		// border-bottom: 1px solid rgba($darkBgColor, 0.1);
		background: #f5f5f5;
		position: relative;

		.stepContent {
			flex: 1;
			justify-content: center;
			h4 {
				font-size: 16px;
				margin-bottom: 0px;
			}
			span {
				font-size: 13px;
				font-style: italic;
			}
			p {
				font-size: 14px;
				margin-top: 5px;
			}
			.editStepWrap {
				display: flex;
				margin-top: 10px;
				textarea {
					font-size: 14px;

					margin-right: 10px;
					width: 100%;
					height: 80px;
					padding: 10px;
					background: white;
				}
				.editbtnWrap {
					button {
						display: block;
						width: 100%;
					}
					.defaultBtn {
						margin-top: 10px;
					}
				}
			}
		}
		.btnWrap {
			max-width: 40px;
			margin-left: 15px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			opacity: 0;
			visibility: hidden;
			transition: all 0.2s linear;
			button {
				cursor: pointer;
				svg {
					width: 15px;
					height: 15px;
					fill: $textDarkColor;
					transition: all 0.2s linear;
				}
				.edit {
					width: 12px;
					height: 12px;
				}
				&:hover {
					svg {
						fill: $themePrimaryColor;
					}
				}
			}
			&.showIcon {
				opacity: 1;
				visibility: visible;
			}
		}
		&:last-child {
			border: 0;
		}
		&:hover {
			.btnWrap {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	.icon {
		margin: 0;
		width: 4px;
		height: 100%;
		display: inline-block;
		top: 0px;
		position: relative;
		position: absolute;
		right: 0;
	}
	.ahead {
		background: $blueColor;
	}
	.behind {
		background: $orangeColor;
	}
	.done {
		background: $generic;
	}
	.ontrack {
		background: $greenColor;
	}
}

.stepsLegend {
	display: flex;
	margin: 2px 0;
	font-size: 13px;
	font-weight: bold;
	color: $textDarkColor;
	span {
		margin-right: 10px;
	}
}
