@import '../../base/base.scss';

.academyChapter {
	h3,
	h4 {
		color: $textDarkColor;
	}
	p {
		margin: 10px 0;
		line-height: 26px;
	}
	.pageContainer {
		flex: 1;
		display: flex;
		flex-direction: column;
	}
	.chapterDetailsWrapper {
		flex: 1;
		height: 100%;
		display: flex;
		padding: 12px;
		border-radius: 15px;
		flex-direction: column;
		.wrapper {
			flex: 1;
			display: flex;
			padding: 12px;
			background: white;
			border-radius: 15px;
			flex-direction: column;
		}
		.flexColumn {
			display: flex;
			flex-direction: column;
			.descRow {
				margin: 20px 0;
				.pillButton {
					display: inline-block;
				}
			}
		}
		.flexBlock {
			.chapterImage {
				width: 180px;
				height: 180px;
				margin-right: 20px;
			}
			.contentSection {
				flex: 1;
				margin-top: 0;
				.columns {
					justify-content: space-between;
					span {
						width: auto;
					}
				}
			}
			.introContent {
				margin: 10px 0;
				p {
					line-height: 28px;
					margin: 0;
					strong {
						color: $textDarkColor;
					}
				}
				h3,
				h2 {
					margin-top: 15px;
					font-weight: 400;
					color: $textDarkColor;
				}
			}
		}
		.chapterSection {
			margin-top: 30px;
			strong {
				color: $textDarkColor;
			}

			.chapterIntroAttachments {
				display: flex;
				margin: 15px -8px;
				.item {
					flex: 1;
					margin: 0 8px;
					min-height: 260px;
					&:only-child {
						min-height: 500px;
					}
					img {
						object-fit: cover;
						width: 100% !important;
						height: 100% !important;
					}
				}
			}
		}
		.linksWrapper {
			margin-top: 20px;
			a {
				font-size: 14px;
				margin-right: 10px;
				&:hover {
					color: $themePrimaryColor;
				}
			}
		}
		.statusBtn {
			width: 170px;
			align-self: flex-end;
		}
	}
}
