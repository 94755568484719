@import '../../base/base.scss';

.courseIntroduction {
	.sectionTitle {
		margin: 25px 0 10px;
	}
	.courseWrapper {
		margin: 0;
		width: 100%;
		.wrapper {
			padding: 20px;
			flex-direction: column;
			align-items: flex-start;
			.flexBlock {
				width: 100%;
				display: flex;
			}
			img {
				width: 180px;
				height: 180px;
				margin-right: 20px;
			}
			.contentSection {
				.columns {
					justify-content: space-between;
					span {
						width: auto;
					}
				}
			}
			.introContent {
				margin: 10px 0;
				p {
					line-height: 28px;
					margin-top: 0;
				}
				h3,
				h2 {
					margin-top: 15px;
					font-weight: 400;
					color: $textDarkColor;
				}
			}
			.courseChapters {
				li {
					margin: 10px 0;
					a {
						text-decoration: underline;
						&:hover {
							color: $themePrimaryColor;
						}
					}
				}
			}
			.confirmation {
				display: flex;
				flex-direction: column;
				align-items: flex-start;

				.flexBlock {
					margin-bottom: 10px;
					input {
						width: 20px;
						height: 20px;
						margin-top: 3px;
						margin-right: 5px;
					}
					label {
						cursor: pointer;
						line-height: 24px;
						width: calc(100% - 20px);
					}
					a {
						color: $textDarkColor;
						text-decoration: underline;
						display: inline-block;
						&:hover {
							color: $themePrimaryColor;
						}
					}
				}

				.quotationBtn {
					margin-top: 10px !important;
				}

				.voucherLink {
					.link {
						cursor: pointer;
						margin-top: 10px;
						color: $themePrimaryColor;
						transition: 0.2s;
						&:hover {
							text-decoration: underline;
						}
					}

					.code {
						margin: 0 3px;
						font-weight: bold;
						color: $themePrimaryColor;
					}

					.validated {
						display: flex;
						margin-top: 10px;
						align-items: baseline;

						button {
							margin-top: 0;
							margin-left: 20px;
						}
					}
				}
				button {
					margin-top: 20px;
				}
			}
		}
	}
}

.courseAttachments {
	width: 100%;
	display: flex;
	margin: 15px 0;
	.item {
		flex: 1;
		margin: 0 5px;
		min-height: 260px;
		&:only-child {
			min-height: 500px;
		}
		img {
			object-fit: cover;
			width: 100% !important;
			height: 100% !important;
		}
	}
}

.courseCertificate {
	p {
		margin: 10px 0;
	}
	button,
	a {
		margin-bottom: 10px;
	}
}

.courseFeedback {
	.row {
		align-items: center;
	}
	.headingStyle {
		button {
			cursor: pointer;
			margin-left: 10px;
			svg {
				width: 12px;
				height: 12px;
			}
			&:hover {
				svg {
					fill: $themePrimaryColor;
				}
			}
		}
	}
	label {
		color: #333;
		font-weight: bold;
		margin: 10px 20px 10px 0;
	}
	p {
		margin: 10px 0;
	}
	textarea {
		width: 500px;
		padding: 10px;
		display: block;
		font-size: 14px;
		line-height: 24px;
		min-height: 120px;
		margin: 15px 0 15px;
		border-color: #ddd;
	}
	button {
		margin-right: 15px;
	}
	.editableRating {
		align-items: center;
		display: inline-flex;
		justify-content: space-between;
		.star-rating {
			&__star {
				font-size: 20px;
				cursor: pointer;
				margin-right: 5px;
				color: lighten($generic, 20%);
				transition: color 0.2s ease-out;

				&.is-selected {
					color: darken($generic, 10%);
				}

				&.is-disabled:hover {
					cursor: default;
				}
			}

			&__checkbox {
				@extend %visually-hidden;
			}
		}
	}
	.afterSubmit {
		opacity: 0;
		display: none;
		margin-top: 10px;
		visibility: hidden;
	}
}

%visually-hidden {
	position: absolute;
	overflow: hidden;
	clip: rect(0 0 0 0);
	height: 1px;
	width: 1px;
	margin: -1px;
	padding: 0;
	border: 0;
}
