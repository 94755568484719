.flexWrapper {
	display: flex;
	align-items: flex-start;
}

.mainWrapper {
	min-width: 1400px;
}

.contentColumn {
	display: flex;
	flex-direction: column;
	background: #fff;
	min-height: 100vh;
	position: sticky;
	top: 0;
	z-index: 2;
	width: calc(100% - 300px);
	.pageContainer {
		padding: 15px;
	}
}

.rightColumn {
	display: flex;
	flex-direction: column;
	width: 300px;
	background: #fff;
	position: sticky;
	top: 0;
}

.loginWrapper {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
	img {
		width: 80%;
		max-width: 300px;
	}
	p {
		margin-top: 20px;
	}
	a {
		margin-left: 5px;
	}
}
