@import '../base/base.scss';

.embedWrapper {
	height: 0;
	width: 100%;
	overflow: hidden;
	border-radius: 8px;
	position: relative;
	padding-top: 56.25%;
	padding-bottom: 48px;
	will-change: transform;
	box-shadow: 0 2px 8px 0 rgba(63, 69, 81, 0.16);

	.iframe {
		top: 0;
		left: 0;
		margin: 0;
		padding: 0;
		width: 100%;
		height: 100%;
		border: none;
		position: absolute;
		box-sizing: border-box;
	}
}
