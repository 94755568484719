@import '../partials/theme';
@import '../partials/mixins';

%perfectCenter {
	position: absolute;
	top: 0px;
	right: 0px;
	bottom: 0px;
	left: 0px;
	margin: auto;
}

%buttons {
	padding: 0 15px;
	line-height: 33px;
	outline: none;
	height: 35px;
	cursor: pointer;
	color: #fff;
	border: 0px;
	font-size: 14px;
	background: $themePrimaryColor;
	border: 1px solid $themePrimaryColor;
	text-align: center;
	@include transition(all 0.2s linear);
	@include border-radius(5px);
	@extend %boxSize;
	&:hover {
		background: #fff;
		border: 1px solid $themePrimaryColor;
		color: $themePrimaryColor;
	}
	&:disabled {
		cursor: not-allowed;
		transition-duration: 0s;
		border-color: $disableBackgroundColor;
		background: $disableBackgroundColor;
	}
}

%subButton {
	font-size: 13px;
	margin-top: 5px;
	cursor: pointer;
	color: $textDarkColor;
	text-decoration: underline;
	transition: color 0.2s linear;
	&:hover {
		color: $themePrimaryColor;
	}
	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;
		color: $textDarkColor;
	}
	&:not(:last-child) {
		margin-right: 12px;
	}
}

%inputFields {
	height: 40px;
	line-height: 40px;
	padding: 0 10px;
	width: 100%;
	margin-bottom: 20px;
	background: white;
	border-radius: 5px;
	border: 1px solid #ddd;
}

%boxSize {
	@include box-sizing();
}

// Animation
@keyframes placeHolderShimmer {
	0% {
		background-position: -468px 0;
	}
	100% {
		background-position: 468px 0;
	}
}

.animated-background {
	animation-duration: 1.25s;
	animation-fill-mode: forwards;
	animation-iteration-count: infinite;
	animation-name: placeHolderShimmer;
	animation-timing-function: linear;
	background: $placeholderColor;
	background: linear-gradient(
		to right,
		$placeholderColor 8%,
		#f0f0f0 18%,
		$placeholderColor 33%
	);
	background-size: 800px 104px;
	height: 96px;
	position: relative;
}
