@import '../../base/base.scss';

.header {
	display: flex;
	color: $textDarkColor;
	align-items: center;
	.periodImage {
		margin-right: 15px;
		max-width: 180px;
		img {
			max-width: 100%;
		}
	}
	.periodDetails {
		p {
			font-size: 16px;
			line-height: 26px;
			margin-bottom: 0;
			i {
				display: inline-block;
				vertical-align: top;
			}
		}
	}
}

.contentSection {
	margin-top: 30px;
	p {
		margin-top: 10px;
		line-height: 26px;
	}
}

.topicWrapper {
	flex: 1;
	display: flex;
	margin: 15px -10px 0;
	align-items: flex-start;
	.column {
		padding: 15px;
		display: flex;
		margin: 0 10px;
		flex: 1 1 33.33%;
		border-radius: 15px;
		background: #f1f1f1;
		flex-direction: column;
		&.taal_topic {
			background: $taal;
		}
		&.rekenen_topic {
			background: $rekenen;
		}
		&.motoriek_topic {
			background: $motoriek;
		}
		&.grote-motoriek_topic {
			background: $grote-motoriek;
		}
		.topicname {
			color: white;
			font-size: 16px;
		}
		.lessonWrapper {
			flex: 1;
			display: flex;
			flex-direction: column;
			.lessonPlaceholder {
				flex: 1;
				width: 100%;
				min-height: 80px;
				display: flex;
				border-radius: 15px;
				background: #fff;
				margin: 15px 0 0 0;
			}
			.lessonSection {
				margin: 15px 0 0 0;
				width: 100%;
				flex: initial;
				img {
					width: 50px;
					margin-right: 10px;
				}
				h1 {
					font-size: 18px;
					line-height: 24px;
					margin-bottom: 5px;
				}
				p {
					font-size: 14px;
					line-height: 20px;
				}
			}
		}
	}
}

.tipsWrapper {
	display: flex;
	flex-direction: column;
	.closeIcon {
		top: 23px;
		z-index: 2;
		right: 23px;
		padding: 5px;
		cursor: pointer;
		position: absolute;
		svg {
			width: 15px;
			height: 14px;
			transform: rotate(180deg);
			transition: all 0.2s linear;
		}
		&:hover {
			svg {
				fill: $themePrimaryColor;
			}
		}
		&.active {
			svg {
				transform: rotate(0deg);
			}
		}
	}
}
.parentTipSection {
	width: 100%;
	padding: 15px;
	display: flex;
	margin: 15px 0 0;
	position: relative;
	border-radius: 15px;
	box-sizing: border-box;
	transition: all 0.2s linear;
	&.closed {
		height: 70px;
		.tipSec {
			overflow: hidden;
		}
	}
	h3 {
		font-size: 16px;
		color: $headingcolor;
	}
	p,
	ul {
		margin: 10px 0;
		font-size: 16px;
		line-height: 26px;
	}
	.goalSec {
		width: 160px;
		padding: 10px;
		overflow: hidden;
		background: white;
		border-radius: 10px;
		img {
			margin: 0 auto;
		}
		p {
			font-size: 14px;
			line-height: 22px;
		}
	}
	.tipSec {
		flex: 1;
		padding: 10px;
		margin-left: 15px;
		background: white;
		border-radius: 10px;
		h3 {
			font-size: 18px;
		}
		.row {
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
			justify-content: space-between;
			& > div {
				padding-right: 10px;
				box-sizing: border-box;
				width: calc(100% - 400px);
			}
			img,
			iframe {
				top: 15px;
				width: 400px;
				position: sticky;
				margin-top: 15px;
				border-radius: 10px;
			}
			iframe {
				height: 250px;
			}
			.subAnchors {
				display: flex;
				flex-wrap: wrap;
			}
			a:not(:last-child) {
				margin-right: 10px;
			}
		}
	}
}
