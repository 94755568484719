.mainContainer .mediaPreviewPopup {
	opacity: 1;
	visibility: visible;
	.popup {
		max-width: 700px;
		&.autoWidth {
			min-width: 600px;
			max-width: 700px;
			max-width: max-content;
		}
		.headingStyle {
			margin-bottom: 10px;
		}
	}
	.loaderContainer {
		top: 0;
		left: 0;
		z-index: 2;
		width: 100%;
		height: 100%;
		overflow: hidden;
		min-height: 400px;
		position: absolute;
		border-radius: 20px;
		background-color: #fff;
		.loaderWrapper {
			width: 200px;
			height: auto;
		}
	}
	p {
		width: 100%;
		line-height: 1.6;
		margin-top: 15px;
		white-space: pre-wrap;
	}
	img,
	video,
	iframe {
		width: 100%;
		min-height: 400px;
	}
}
