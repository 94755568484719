@import '../base/base.scss';

.managementContainer {
	.breadCrumb {
		padding: 0 15px 15px;
		ul {
			margin: 0;
			padding: 0;
			list-style-type: none;
			display: flex;
			li {
				button,
				a {
					font-size: 14px;
					-webkit-appearance: none;
					border: 0;
					cursor: pointer;
					color: #333;
					margin-right: 10px;
					transition: all 0.2s linear;
				}
				.active {
					color: $themePrimaryColor;
				}
			}
		}
	}
}

.resultatenContainer,
.curriculumContainer {
	@extend .managementContainer;
}

.curriculumContainer {
	padding-left: 0;
	padding-right: 0;
}

.manageParnasys {
	@extend .mergeableStudents;
}

.mergeableStudents {
	display: flex;
	flex-direction: column;
	p {
		margin-top: 20px;
		line-height: 24px;
	}
	.step-1,
	.step-2,
	.step-3,
	.step-4 {
		display: flex;
		flex-direction: column;
		flex: 1;
	}
	.step-2,
	.step-3,
	.step-4 {
		display: none;
		opacity: 0;
		visibility: hidden;
	}
	.wrapper {
		flex: 1;
	}
	.convert {
		width: 50px;
		height: auto;
		margin: 0 50px;
		fill: $blueColor;
		align-self: center;
	}
	.buttonsWrapper {
		margin-top: 20px;
		button {
			margin-right: 15px;
		}
		.disable {
			cursor: not-allowed;
			&:hover {
				color: $textDarkColor;
				border-color: $textDarkColor;
			}
		}
	}
	.studentBox {
		padding: 10px;
		cursor: pointer;
		margin: 20px 10px 0;
		width: calc(25% - 20px);
		transition: all 0.2s linear;
		border: 1px solid transparent;
		&.active {
			background: #f9f9f9;
			box-sizing: border-box;
			border: 1px solid $themePrimaryColor;
		}
		.fromto {
			width: 100%;
			font-size: 16px;
			text-align: left;
			margin-bottom: 10px;
		}
		&:hover {
			background: #f9f9f9;
		}
	}
}

.settingsWrapper {
	margin-top: 30px;
	.row {
		display: flex;
		padding: 10px 0px;
		align-items: center;
		input {
			width: 15px;
			height: 15px;
			margin-right: 10px;
			cursor: pointer;
		}
		label {
			cursor: pointer;
		}
	}
}
