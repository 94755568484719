@import '~css/base/base.scss';

.notesTitleWrapper {
	display: flex;
	.buttonsWrapper {
		display: flex;
		margin-top: 20px;
		align-items: center;
		button {
			@extend %subButton;
			font-size: 14px;
			font-weight: 400;
		}
	}
}

.notesContainer {
	padding: 20px;
	display: flex;
	textarea {
		width: 100%;
		padding: 10px;
		font-size: 16px;
		line-height: 30px;
		min-height: 300px;
		border-radius: 10px;
	}
}
