@import '~css/base/base.scss';

.onderbouwdCurriculum {
	.curriculumContainer {
		padding: 20px;
		border-radius: 20px;
		background-color: #999;
	}
	.groupResultsWrapper {
		overflow: initial;
		.title {
			margin-top: 0;
			margin-left: 0;
			margin-bottom: 20px;
		}
		& p.pageContent {
			padding-left: 0;
			margin-bottom: 20px;
		}
		.themeList {
			margin-top: 0;
			display: flex;
			padding-left: 0;
			margin-bottom: 0;
			list-style-type: none;
			align-items: flex-start;
			justify-content: space-between;
			li.goalList {
				flex: 1;
				padding: 5px;
				display: flex;
				color: $white;
				border-radius: 5px;
				flex-direction: column;
				&:not(:last-child) {
					margin-right: 20px;
				}
				h2 {
					margin: 5px 0 10px;
					font-size: 16px;
				}
				ul {
					width: 100%;
					display: flex;
					padding-left: 0;
					margin-bottom: 0;
					list-style-type: none;
					flex-direction: column;
					.goalItem {
						display: flex;
						padding: 10px;
						border-radius: 5px;
						align-items: center;
						color: $textDarkColor;
						background-color: $white;
						&:not(:last-child) {
							margin-bottom: 5px;
						}
						input {
							margin-right: 10px;
						}
						label {
							flex: 1;
							color: #333;
							cursor: pointer;
							font-weight: normal;
							transition: all 0.2s linear;
							&:hover {
								color: $themePrimaryColor;
							}
						}
					}
				}
			}
			.taal {
				background-color: $taal;
				&.period {
					background-color: rgba($taal, 0.3);
				}
			}
			.herfst {
				background-color: $herfstSeason;
				&.period {
					background-color: rgba($herfstSeason, 0.3);
				}
			}
			.winter {
				background-color: $winterSeason;
				&.period {
					background-color: rgba($winterSeason, 0.3);
				}
			}
			.lente {
				background-color: $lenteSeason;
				&.period {
					background-color: rgba($lenteSeason, 0.3);
				}
			}
			.zomer {
				background-color: $zomerSeason;
				&.period {
					background-color: rgba($zomerSeason, 0.3);
				}
			}
		}
	}
}
