@import '../base/base.scss';

$schoolfont: 'Schoolbell', cursive;

.academieModal {
	display: flex;
	margin-bottom: 60px;
	.imageBox {
		width: 43%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		img {
			top: 30px;
			width: 100%;
			object-fit: cover;
			position: sticky;
			@include border-radius(5px);
		}
	}
	.contentSec {
		display: flex;
		width: 57%;
		padding: 20px 30px;
		justify-content: center;
		flex-direction: column;
		&:last-of-type {
			width: 100%;
		}
		h2,
		h1 {
			font-size: 32px;
			margin-bottom: 15px;
			color: $blueColor;
			font-family: $schoolfont;
		}
		h3 {
			font-size: 24px;
			margin-bottom: 15px;
			color: $headingcolor;
		}
		i,
		em {
			vertical-align: top;
			display: inline-block;
		}
		figure {
			margin: 0;
			iframe {
				width: 100%;
				height: 300px;
				margin-top: 30px;
				border-radius: 5px;
			}
		}
		p,
		& > div {
			color: $textColor;
			line-height: 24px;
			margin: 5px 0;
			a {
				color: $blueColor;
				display: inline-block;
				vertical-align: top;
			}
			a.readmorebtn {
				background: none;
				color: #1588c2;
				border: none;
				text-decoration: underline;
				padding: 0;
				line-height: 30px;
				&:hover {
					text-decoration: none;
					border: 0;
				}
			}
		}
		.moreContent {
			height: 0;
			overflow: hidden;
		}
		button.readmorebtn {
			@extend %buttons;
			width: 135px;
			text-align: center;
			padding: 0 20px;
			margin-right: 8px;
			color: #fff;
			background: $blueColor;
			border-color: $blueColor;
			text-decoration: none;
			&:hover {
				background: transparent;
				color: $blueColor;
				border-color: $blueColor;
			}
		}
		.inlineContainer {
			margin-top: 10px;
			a.readmorebtn,
			a.actionlink {
				@extend %buttons;
				padding: 0 20px;
				margin-right: 8px;
				color: #fff;
				background: $blueColor;
				border-color: $blueColor;
				text-decoration: none;
				&:hover {
					background: transparent;
					color: $blueColor;
					border-color: $blueColor;
				}
			}
			.outlineButton,
			.link-2 {
				border-color: #333;
				color: #333;
				background: transparent;
				text-transform: uppercase;
				&:hover {
					color: #fff;
					background: #333;
					border-color: #333;
				}
			}
		}
	}
	&:nth-child(even) {
		flex-direction: row-reverse;
		.imageBox {
			margin-left: 0;
			margin-right: 30px;
		}
	}
}

.imageSectionModal {
	@extend .academieModal;
	.contentSec {
		h2,
		h1 {
			color: #1588c2;
			color: #37b2fc;
			font-family: $schoolfont;
		}
		strong {
			margin-bottom: 10px;
		}
		.inlineContainer {
			a {
				background: #37b2fc;
				border-color: #37b2fc;
				&:hover {
					color: #37b2fc;
					border-color: #37b2fc;
				}
			}
		}
	}
}

.nieuwsScreen {
	padding-top: 50px;
	h4 {
		color: $headingcolor;
		margin-bottom: 20px;
	}
	p {
		margin-bottom: 40px;
		line-height: 24px;
	}
	strong {
		font-weight: normal;
	}
	.newsComponent {
		@extend .academieModal;
		// @media (max-width: 760px) {
		// 	flex-direction: column-reverse !important;
		// }
		.imageBox {
			h2,
			h1 {
				font-size: 26px;
				margin-bottom: 0px;
				color: $blueColor;
				font-family: $schoolfont;
				// @media (min-width: 761px) {
				display: none;
				// }
			}
			& > strong {
				color: $textColor;
				font-weight: 400;
				font-size: 14px;
				margin: 8px 0 15px;
				// @media (min-width: 761px) {
				display: none;
				// }
			}
		}
		.contentSec {
			h2 {
				font-size: 28px;
				margin-bottom: 0px;
				// @media (max-width: 760px) {
				// 	&:not(.nohide) {
				// 		display: none;
				// 	}
				// }
			}
			> strong {
				color: $textColor;
				font-size: 14px;
				margin: 8px 0 15px;
				// @media (max-width: 760px) {
				// 	&:not(.nohide) {
				// 		display: none;
				// 	}
				// }
			}
			p {
				margin-bottom: 0;
				a {
					color: $themePrimaryColor;
					&:hover {
						text-decoration: underline;
					}
				}
				strong {
					margin: 0;
				}
			}
		}
	}
}
