@import '~css/base/base.scss';

.managethemesWrapper,
.manageLessonsWrapper,
.routineLessonsWrapper {
	.groupResultsWrapper {
		overflow: initial;
		.title {
			margin-left: 0;
		}
		.pageintro {
			margin: 0;
			padding: 0;
			margin-top: -20px;
			margin-bottom: 30px;
		}
		.themeList {
			margin-top: 0;
			padding-left: 0;
			margin-bottom: 0;
			display: inline-flex;
			list-style-type: none;
			flex-direction: column;
			li {
				color: #fff;
				& > button:not(.toggleBtn) {
					flex: 1;
					color: #fff;
					display: flex;
					cursor: pointer;
					padding: 5px 0;
					text-align: left;
					font-weight: bold;
					justify-content: space-between;
					svg {
						width: 20px;
						height: auto;
						fill: white;
						margin-right: 5px;
						transform: rotate(180deg);
						transition: transform 0.2s linear;
					}
					&.active {
						svg {
							transform: rotate(0deg);
						}
					}
				}
				display: flex;
				min-width: 400px;
				border-radius: 5px;
				padding: 10px 10px;
				margin-bottom: 15px;
				align-items: center;
				justify-content: space-between;
				&.period {
					font-weight: normal;
					color: $textDarkColor;
				}
				&.periodContainer {
					padding: 0;
					border-radius: 0;
					margin-bottom: 0;
					overflow: hidden;
					&:not(.active) {
						height: 0;
					}
					&.flexRow {
						flex-direction: column;
					}
					.PeriodWrap {
						width: 100%;
						display: flex;
						flex-direction: column;
						.period,
						.lesson {
							display: flex;
							border-radius: 5px;
							padding: 10px 10px;
							margin-bottom: 15px;
							align-items: center;
							font-weight: normal;
							color: $textDarkColor;
							justify-content: space-between;
						}
						.archived {
							background-color: rgba(51, 51, 51, 0.1);
							text-decoration: line-through;
						}
						.lesson {
							background-color: rgba($darkBgColor, 0.3);
							&.archived {
								span {
									font-style: italic;
									text-decoration: line-through;
									color: rgba($darkBgColor, 0.5);
								}
								button {
									background-color: rgba($textDarkColor, 0.7);
								}
								background-color: rgba($textDarkColor, 0.1);
							}
						}
					}
				}

				.toggleBtn {
					width: 30px;
					height: 30px;
					display: flex;
					cursor: pointer;
					border-radius: 3px;
					color: $white-gray;
					align-items: center;
					justify-content: center;
					background-color: $darkBgColor;
					svg {
						width: 12px;
						height: auto;
						fill: white;
					}
				}
			}
			.herfst {
				background-color: $herfstSeason;
				&.period {
					background-color: rgba($herfstSeason, 0.3);
				}
			}
			.winter {
				background-color: $winterSeason;
				&.period {
					background-color: rgba($winterSeason, 0.3);
				}
			}
			.lente {
				background-color: $lenteSeason;
				&.period {
					background-color: rgba($lenteSeason, 0.3);
				}
			}
			.zomer {
				background-color: $zomerSeason;
				&.period {
					background-color: rgba($zomerSeason, 0.3);
				}
			}
		}
	}
}
.themeAddEditWrapper {
	.startDateWrap {
		.dropDownWrap {
			display: flex;
			select {
				height: 40px;
				color: #333;
				min-width: 135px;
				border-radius: 5px;
				margin-bottom: 20px;
				border-color: #ddd;
				&:first-child {
					margin-right: 10px;
				}
			}
		}
	}
}
