@import '~css/base/base.scss';

.mediaPopupWrapper {
	opacity: 1 !important;
	visibility: visible !important;
}

.mediaPopupWrapper {
	.mediaPopup {
		z-index: 3;
		width: 900px;
		padding: 20px;
		display: flex;
		margin: 80px auto;
		position: relative;
		border-radius: 10px;
		flex-direction: column;
		background: $white-gray;
		.popup {
			flex: 1;
			margin: 0;
			min-height: 65vh;
			max-height: 68vh;
			&:last-child {
				margin-left: 15px;
			}
			&.studentListPopup {
				flex: 0.6;
				display: flex;
				flex-direction: column;
			}
			.switchWrapper {
				height: 50px;
				margin-bottom: 20px;
				border-radius: 10px;
				background-color: $light-gray;
				button {
					width: 50%;
					cursor: pointer;
					font-weight: 400;
					text-align: center;
					border-radius: 10px;
					color: $textDarkColor;
					transition: all 0.2s linear;
					&:not(.active) {
						&:hover {
							color: $themePrimaryColor;
						}
					}
					&.active {
						color: $white;
						background-color: $themePrimaryColor;
					}
				}
			}
			.studentListContainer {
				overflow: auto;
				flex-direction: column;
				.studentButton {
					flex: 1;
					width: 100%;
					cursor: pointer;
					padding: 0 10px;
					border-radius: 10px;
					align-items: center;
					background-color: $light-gray;
					&:not(:last-child) {
						margin-bottom: 10px;
					}
					.checkBox {
						width: 12px;
						height: 12px;
						margin-right: 5px;
						border-radius: 2px;
						box-sizing: border-box;
						background-color: $white;
						border: 1px solid $darkBgColor;
					}
					.studentWrap {
						width: 100%;
						margin-bottom: 0;
						text-align: left;
						.name {
							transition: color 0.2s linear;
						}
					}
					&.active,
					&:hover {
						.studentWrap {
							.name {
								color: $themePrimaryColor;
							}
						}
					}
					&.active {
						.checkBox {
							display: flex;
							align-items: center;
							justify-content: center;
							&:after {
								content: '';
								width: 6px;
								height: 6px;
								background-color: $themePrimaryColor;
							}
						}
					}
					&:disabled {
						filter: grayscale(1);
					}
				}
			}
			&.mediaInputPopup {
				display: flex;
				flex-direction: column;
				.aspectContainer {
					padding-top: 56.25%;
					margin-bottom: 20px;
					.wrapper {
						top: 0;
						left: 0;
						z-index: 1;
						width: 100%;
						height: 100%;
						position: absolute;
						background-color: $placeholderColor;
						.picturePlaceholderWrapper {
							top: 10px;
							left: 10px;
							right: 10px;
							bottom: 10px;
							display: flex;
							position: absolute;
							align-items: center;
							color: $textDarkColor;
							flex-direction: column;
							justify-content: center;
							border: 2px dashed $darkBgColor;
							.downloadIcon {
								width: 50px;
								margin-bottom: 20px;
								fill: $textDarkColor;
							}
						}
						.updateIcon {
							top: 10px;
							z-index: 2;
							right: 10px;
							position: absolute;
							fill: $themePrimaryColor;
						}
						img,
						video,
						iframe {
							top: 0;
							z-index: 1;
							width: 100%;
							height: 100%;
							object-fit: cover;
							position: absolute;
							border-radius: 4px;
							box-sizing: border-box;
							border: 1px solid rgba($darkBgColor, 0.1);
						}
					}
					input {
						z-index: 3;
						opacity: 0;
						width: 100%;
						height: 100%;
						cursor: pointer;
						position: absolute;
					}
				}
				.inputField {
					@extend %inputFields;
					&:last-child {
						flex: 1;
						height: auto;
						margin-bottom: 0;
					}
				}
			}
		}
	}
	.progressTrack {
		width: 100%;
		height: 8px;
		margin-top: -5px;
		overflow: hidden;
		position: relative;
		border-radius: 10px;
		transform: translateY(-8px);
		background-color: $light-gray;
		&.loading {
			background-color: $themePrimaryColor;
			background-image: linear-gradient(
				-45deg,
				rgba(255, 255, 255, 0.125) 25%,
				transparent 25%,
				transparent 50%,
				rgba(255, 255, 255, 0.125) 50%,
				rgba(255, 255, 255, 0.125) 75%,
				transparent 75%,
				transparent
			);
			background-size: 35px 35px;
			-webkit-animation: cssProgressActive 2s linear infinite;
			-ms-animation: cssProgressActive 2s linear infinite;
			animation: cssProgressActive 2s linear infinite;
		}
		.progressbar {
			width: 0%;
			height: 100%;
			display: block;
			transition: width 0.3s linear;
			background-color: $themePrimaryColor;
		}
	}
	.buttonsWrapper {
		margin-top: 20px;
		button:disabled {
			&:hover {
				color: $white;
			}
		}
	}
	.error {
		color: red;
		margin-top: 20px;
	}
}

@-webkit-keyframes cssProgressActive {
	0% {
		background-position: 0 0;
	}
	100% {
		background-position: 35px 35px;
	}
}
@-ms-keyframes cssProgressActive {
	0% {
		background-position: 0 0;
	}
	100% {
		background-position: 35px 35px;
	}
}
@keyframes cssProgressActive {
	0% {
		background-position: 0 0;
	}
	100% {
		background-position: 35px 35px;
	}
}
