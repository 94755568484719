@import '~css/base/base.scss';

div.mediaListPopup {
	opacity: 1;
	display: block;
	visibility: visible;
	.popup {
		max-width: 720px;
		.mediaPreviewBox {
			cursor: pointer;
			align-items: stretch;
			img,
			video,
			strong {
				box-sizing: border-box;
				transition: color 0.2s linear;
			}
			&.active {
				img,
				video {
					border: 1px solid $themePrimaryColor;
				}
				strong {
					color: $themePrimaryColor;
				}
			}
		}
		.studentListWrapper .studentBox strong {
			font-size: 14px;
		}
		.row {
			margin-top: 20px;
		}
	}
}
