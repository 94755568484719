@import '../base/base.scss';

$border-color: #cacaca;
$row-background: #f5f5f5;

.resultatenContainer {
	.groupResultsWrapper {
		padding-top: 25px;
		overflow: initial;
	}
}

.dropdownWrapper {
	z-index: 4;
	display: flex;
	padding: 0 20px;
	position: relative;
	margin-bottom: 20px;
	justify-content: space-between;
	select {
		height: 30px;
		padding: 0 5px;
		border-radius: 5px;
		color: $textDarkColor;
		border-color: $textDarkColor;
		margin: 0 15px 0 0;
	}
	.customDropdown {
		top: 0;
		right: 60px;
		width: 180px;
		border-radius: 5px;
		background: white;
		position: absolute;
		color: $textDarkColor;
		border: 1px solid $textDarkColor;
		.dropdownTitle {
			line-height: 30px;
			padding: 0 10px;
			cursor: pointer;
			display: flex;
			justify-content: space-between;
			svg {
				width: 10px;
				fill: $textDarkColor;
			}
		}
		.dropdownResults {
			height: 0;
			overflow: auto;
			.student {
				display: flex;
				font-size: 14px;
				padding: 0 10px;
				min-height: 35px;
				align-items: center;
				border-bottom: 1px solid rgba($textDarkColor, 0.2);
				&:first-child {
					border-top: 1px solid rgba($textDarkColor, 0.2);
				}
				label {
					cursor: pointer;
					padding: 5px 0px;
				}
				input {
					cursor: pointer;
					margin-right: 8px;
				}
				&:hover {
					background: #f5f5f5;
				}
			}
		}
		button {
			margin: 10px;
		}
	}

	.flexEnd {
		display: flex;
		align-items: center;
		align-self: flex-end;
		justify-content: center;
		.printButton {
			top: 0px;
			right: 0px;
			margin-left: 10px;
			position: relative;
		}
	}
}

.resultsTable {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 20px;
	position: relative;
	.titleRow {
		display: flex;
		flex: 1;
		position: sticky;
		top: 00px;
		z-index: 2;
		&:nth-child(odd) {
			background: $row-background;
		}
		.column {
			flex: 1;
			display: flex;
			padding: 10px;
			height: 50px;
			align-items: center;
			font-weight: bold;
			color: $textDarkColor;
			font-size: 14px;
			&:not(:last-child) {
				word-break: break-word;
				border-right: 1px solid $border-color;
			}
		}
		.alignCenter {
			justify-content: center;
		}
	}
	.resultRow {
		@extend .titleRow;
		position: relative;
		z-index: 1;
		top: 0;
		.column {
			font-weight: normal;
			opacity: 0.8;
			padding: 0 8px;
			@media print {
				padding: 6px 4px;
			}
			&.error {
				color: red;
			}
			&.errorHigh {
				color: blue;
			}
			&.errorNoData {
				color: grey;
			}

		}
		&:nth-child(even) {
			background: white;
		}
	}
	.footerRow {
		background-color: gray !important;
		.column {
			color: #eee;
		}
	}
}
