@import '../base/base.scss';

.socialWrapper {
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
  background: #fff;
  padding: 30px 20px;
  border-radius: 15px;
  &:nth-of-type(1) {
    margin-top: 20px;
  }
  img {
    // max-width: 180px;
    margin-right: 20px;
  }
  .socialContent {
    h2 {
      color: $textDarkColor;
      font-size: 18px;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      li {
        margin: 20px 0 0;
        div {
          display: flex;
          align-items: center;
          input {
            width: 15px;
            height: 15px;
          }
          label,
          span {
            margin-left: 5px;
            margin-right: 10px;
          }
          label {
            cursor: pointer;
          }
        }
      }
    }
  }
}
