@import '../base/base.scss';

.studentReportWrapper {
	position: relative;
	.studentIntroSec {
		padding: 0;
	}
	.headingStyle {
		margin: 15px 0;
	}
	.formWrapper {
		max-width: 100%;
		.inputField,
		textarea {
			max-width: 800px;
		}
		textarea {
			height: 200px;
			padding: 10px;
		}
		.checkboxWrapper {
			margin-top: 10px;
			margin-bottom: 15px;
			flex-wrap: nowrap;
			.column {
				flex: 1;
				padding-right: 20px;
			}
			.checkboxTitle {
				color: $textDarkColor;
			}
			.checkBox {
				width: 100%;
			}
			.subCheckboxWrap {
				padding-left: 25px;
			}
		}
	}
	.details {
		margin-top: 15px;
		font-size: 14px;
		line-height: 20px;
	}
	.addReport,
	.addPhoto {
		height: 40px;
		width: 100px;
		border-radius: 5px;
		box-sizing: border-box;
		cursor: pointer;
		svg {
			width: 20px;
			height: 100%;
			fill: $white;
			transition: all 0.2s linear;
		}
		&:hover {
			border-color: $themePrimaryColor;
			svg {
				fill: $themePrimaryColor;
			}
		}
	}
	.addPhoto {
		background-color: $themeGrayColor;
		border-color: $themeGrayColor;
		&:hover {
			background-color: $disableBackgroundColor;
			border-color: $disableBackgroundColor;
			svg {
				fill: $white;
			}
		}
	}
	.reportListWrapper {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		flex-direction: column;
		width: 60%;
		flex: 1 1 60%;
		.titleBar {
			width: 100%;
			display: flex;
			margin-top: 8px;
			margin-bottom: 20px;
			justify-content: center;
			align-items: space-between;
			h2 {
				font-size: 18px;
				margin: 0;
				flex: 1 1 100%;
			}
		}
		.row {
			width: 100%;
			display: flex;
			margin: 2px 0;
			font-size: 16px;
			padding: 10px 0;
			align-items: center;
			&.header,
			&.header .date {
				font-weight: bold;
				font-size: 16px;
			}
			&:not(.header):hover {
				background: #f5f5f5;
			}
			button {
				cursor: pointer;
				height: 20px;
				svg {
					width: 20px;
					height: 20px;
					fill: #333;
				}
				&:hover {
					svg {
						fill: $themePrimaryColor;
					}
				}
			}
			.name {
				flex: 1;
				a {
					display: inline-flex;
					text-decoration: underline;
					transition: all 0.2s linear;
					&:hover {
						color: $themePrimaryColor;
					}
				}
				.loaderWrapper {
					min-height: 25px;
					justify-content: flex-start;
					background-color: transparent;
					& > div {
						margin: 0px !important;
						max-width: 40px !important;
					}
				}
			}
			.date {
				width: 120px;
				display: flex;
				font-size: 14px;
				text-align: center;
				justify-content: center;
				.reportLoading {
					width: 30px;
				}
			}
			.parentVisible,
			.delete {
				width: 100px;
				text-align: center;
			}
		}
	}
	.teacherCommentWrapper {
		width: 40%;
		flex: 1 1 40%;
		margin: 0px 20px 0;
		h2 {
			margin: 0;
			font-size: 18px;
		}
		textarea {
			width: 100%;
			margin: 20px 0;
			height: 320px;
			padding: 10px;
			border: 1px solid $disableTextColor;
		}
		.buttonsWrapper {
			justify-content: flex-end;
			align-items: center;
		}
		button {
			float: right;
			margin-left: 15px;
		}
		.disableBtn {
			background: lightgray;
			border: #333;
			color: #333;
			cursor: not-allowed;
		}
	}
	.photosWrapper {
		margin-top: 10px;
		.photoBox {
			width: 100px;
			margin-right: 10px;
			position: relative;
			.aspectContainer {
				padding-top: 56.25%;
				img,
				video {
					top: 0;
					width: 100%;
					height: 100%;
					position: absolute;
					border-radius: 5px;
					pointer-events: none;
				}
			}
			.closeBtn {
				margin: 0;
				top: -5px;
				z-index: 2;
				right: -5px;
				width: 20px;
				height: 20px;
				cursor: pointer;
				position: absolute;
				border-radius: 100%;
				background-color: $themePrimaryColor;
				svg {
					width: 8px;
					height: 8px;
					fill: $white;
				}
			}
		}
		.addPhoto {
			height: auto;
			margin-right: 0;
			min-height: 50px;
			&:disabled {
				color: #333;
				border: #333;
				cursor: not-allowed;
				background: lightgray;
				&:hover {
					svg {
						fill: $white;
					}
				}
			}
		}
	}
}
