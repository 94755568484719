@import '../../base/base.scss';

.voucherPopup {
	.popup {
		height: 120px;
		h2 {
			text-align: center;
			margin-bottom: 20px;
		}
	}

	.voucherContent {
		display: flex;
		margin: 10px 0;
		cursor: pointer;
		line-height: 28px;
		align-items: baseline;
		justify-content: center;

		input {
			height: 40px;
			width: 300px;
			padding: 0 10px;
			line-height: 40px;
			border-radius: 5px;
			background: white;
			border: 1px solid #ddd;
		}

		button,
		p,
		input {
			margin-left: 20px;
		}
	}

	.message {
		margin-top: 20px;
		text-align: center;
		color: $themePrimaryColor;
		&.error {
			color: $taal;
		}
	}
}
