@import '../base/base.scss';

$marginTop: 30px;

.faqWrapper {
	.sectionTitle {
		margin-top: 20px;
		font-weight: bold;
	}
	ul {
		margin: 0;
		padding: 0;
		margin-bottom: 50px;
		list-style-type: none;
	}
	.faq {
		padding: 10px 15px;
		margin-top: $marginTop;
		border-left: 2px solid $themePrimaryColor;
		button {
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			svg {
				width: 14px;
				height: auto;
				margin-left: 10px;
				transform-origin: center;
				transform: rotate(180deg);
				transition: all 0.2s linear;
			}
			&.active {
				svg {
					transform: rotate(0deg);
				}
			}
		}
		.faqQuestion {
			font-size: 16px;
			font-weight: bold;
			color: $textDarkColor;
		}
		.faqAnswer {
			height: 0;
			line-height: 1.6;
			overflow: hidden;
			color: $textColor;
			strong {
				font-weight: 400;
			}
		}
	}
}

.documentWrapper {
	@extend .faqWrapper;
	.faqAnswer {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		a {
			transition: all 0.2s linear;
			border-bottom: 1px solid white;
			display: inline-flex;
			margin-top: 10px;
			&:hover {
				color: $themePrimaryColor;
				border-color: $themePrimaryColor;
			}
		}
	}
}
