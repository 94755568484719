@import '../base/base.scss';

.studentWrap {
  display: flex;
  overflow: hidden;
  margin-bottom: 6px;
  position: relative;
  align-items: center;
  border-radius: 10px;
  background: #f5f5f5;
  transition: all 0.2s linear;
  img:not(.icon) {
    margin: 5px;
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 10px;
    background-color: lighten(gray, 46%);
  }
  .textWrap {
    flex: 1;
    min-width: 0;
    display: flex;
    overflow: hidden;
    flex-direction: column;
  }
  .name {
    flex: 1;
    font-size: 15px;
    overflow: hidden;
    font-weight: 300;
    padding-right: 15px;
    white-space: nowrap;
    color: $textDarkColor;
    text-overflow: ellipsis;
  }
  .age,
  .groupname {
    font-size: 10px;
    margin-top: 2px;
    color: $textDarkColor;
  }
  .icon {
    right: 0;
    width: 4px;
    margin: 0px;
    height: 100%;
    position: absolute;
  }
  .ahead {
    background: $blueColor;
  }
  .behind {
    background: $orangeColor;
  }
  .ontrack {
    background: $greenColor;
  }
}

.activeStudent {
  .studentWrap {
    // background: $themePrimaryColor;
    span {
      color: $themePrimaryColor;
    }
  }
}
