@import '../base/base.scss';

.annualPlanWrapper {
	overflow: hidden;
	background: #fff;
	position: relative;
	border-radius: 15px;
	box-sizing: border-box;
	min-height: calc(100vh - 110px);
	h1 {
		font-size: 20px;
		color: $headingcolor;
		margin: 20px 20px 30px;
	}

	.studentColumn {
		width: 20%;
		min-width: 250px;
		@media print {
			width: 140px;
			min-width: initial;
		}
	}

	.row {
		&:not(.headerRow) {
			@media print {
				height: 55px;
			}
		}
		.groupColumn .row .step {
			width: 80px;
			position: relative;
			@media print {
				width: 60px;
			}
		}
	}

	.addboxStyle,
	.dots {
		cursor: pointer;
	}
	.dotWrapper {
		position: absolute;
		.dots {
			width: 15px;
			height: 15px;
			margin: 0 5px;
			border-radius: 50%;
			display: inline-flex;
			box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
			&.darkdot {
				background: lighten(black, 30%);
			}
			&.lightdot {
				background: lighten(black, 60%);
			}
			&.checkdot {
				display: inline-flex;
				align-items: center;
				justify-content: start;
				user-select: none;
				box-shadow: none;
				& svg {
					margin-top: -2px;
				}
			}
		}
	}
	.studentWrap {
		background: white;
	}

	& .row .groupColumn .goalRow .goalName {
		top: 0;
		position: relative;
		@media print {
			writing-mode: initial;
			transform: rotate(0deg);
		}
	}
}

.taal_wrap {
	.row .groupColumn .row .currentStep.darkBox {
		background: lighten($taal, 8%);
	}
	.row .groupColumn .row .currentStep.lightBox {
		background: lighten($taal, 30%);
	}
	.row .groupColumn .row .dots.lightdot {
		background: lighten($taal, 30%);
	}
	.row .groupColumn .row .dots.darkdot {
		background: lighten($taal, 3%);
	}
	.row .groupColumn .row .dots.checkdot svg {
		fill: $taal;
	}
}
.rekenen_wrap {
	.row .groupColumn .row .currentStep.darkBox {
		background: lighten($rekenen, 8%);
	}
	.row .groupColumn .row .currentStep.lightBox {
		background: lighten($rekenen, 30%);
	}
	.row .groupColumn .row .dots.lightdot {
		background: lighten($rekenen, 30%);
	}
	.row .groupColumn .row .dots.darkdot {
		background: lighten($rekenen, 3%);
	}
	.row .groupColumn .row .dots.checkdot svg {
		fill: $rekenen;
	}
}
.motoriek_wrap {
	.row .groupColumn .row .currentStep.darkBox {
		background: lighten($motoriek, 8%);
	}
	.row .groupColumn .row .currentStep.lightBox {
		background: lighten($motoriek, 30%);
	}
	.row .groupColumn .row .dots.lightdot {
		background: lighten($motoriek, 30%);
	}
	.row .groupColumn .row .dots.darkdot {
		background: lighten($motoriek, 3%);
	}
	.row .groupColumn .row .dots.checkdot svg {
		fill: $motoriek;
	}
}
.grote-motoriek_wrap {
	.row .groupColumn .row .currentStep.darkBox {
		background: lighten($grote-motoriek, 4%);
	}
	.row .groupColumn .row .currentStep.lightBox {
		background: lighten($grote-motoriek, 15%);
	}
	.row .groupColumn .row .dots.lightdot {
		background: lighten($grote-motoriek, 15%);
	}
	.row .groupColumn .row .dots.darkdot {
		background: lighten($grote-motoriek, 0.2%);
	}
	.row .groupColumn .row .dots.checkdot svg {
		fill: $grote-motoriek;
	}
}

.lichaam_wrap {
	.row .groupColumn .row .currentStep.darkBox {
		background: lighten($lichaam, 4%);
	}
	.row .groupColumn .row .currentStep.lightBox {
		background: lighten($lichaam, 15%);
	}
	.row .groupColumn .row .dots.lightdot {
		background: lighten($lichaam, 15%);
	}
	.row .groupColumn .row .dots.darkdot {
		background: lighten($lichaam, 0.2%);
	}
	.row .groupColumn .row .dots.checkdot svg {
		fill: $lichaam;
	}
}

.printButton {
	position: absolute;
	right: 20px;
	top: 20px;
	cursor: pointer;
	svg {
		fill: $textDarkColor;
		width: 25px;
		height: 25px;
		transition: all 0.2s linear;
	}
	&:hover {
		svg {
			fill: $themePrimaryColor;
		}
	}
}
