@import '~css/base/base.scss';

.obsPopup {
	width: 1020px;
	background: #f1f1f1;
	position: relative;
	z-index: 3;
	border-radius: 10px;
	margin: 80px auto;
	padding: 20px;
	display: flex;
	.popup {
		margin: 0 0 !important;
		position: static !important;
		min-height: 520px;
		flex: 1;
		&:last-child {
			margin-left: 15px !important;
		}
		h2 {
			font-size: 16px;
			margin: 10px 0px;
			color: $textDarkColor;
		}
		.infoBlock {
			p {
				font-size: 14px;
				line-height: 20px;
			}
		}
	}
	.studentDetail {
		p {
			margin: 5px 0 10px;
		}
		strong {
			margin-top: 5px;
			font-size: 14px;
		}
	}
	.commentsWrapper {
		.historyWrapper {
			position: static;
			.studentWrap {
				& > div h3 {
					padding-right: 0;
				}
				img {
					width: 70px;
					height: 70px;
					margin: 0 10px 0 0;
					border-radius: 10px;
				}
			}
			.stepHistoryWrap {
				max-height: 580px;
				overflow: auto;
			}
		}
	}
	.legendsWrap {
		margin-top: 15px;
		h2 {
			margin-top: 0;
		}
		p {
			font-weight: normal;
		}
	}
}
