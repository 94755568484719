@import '../../base/base.scss';

.courseChapter {
	padding: 6px;
	margin: 0 10px 10px;
	border-radius: 10px;
	color: $textDarkColor;
	.wrapper {
		padding: 10px;
		border-radius: 7px;
		background: white;
		.intro {
			display: flex;
			align-items: center;
		}
		.flexColumn {
			flex: 1;
			display: flex;
			flex-direction: column;
		}
		img {
			width: 50px;
			margin-right: 10px;
		}
		strong {
			font-size: 14px;
			color: $textDarkColor;
			&:hover {
				color: $themePrimaryColor;
			}
		}
		.progress {
			width: 100%;
			height: 15px;
			display: block;
			margin-top: 10px;
			background: $white-gray;
			.fill {
				height: 15px;
				color: white;
				display: block;
				font-size: 10px;
				line-height: 15px;
				text-align: right;
				background: $generic;
				box-sizing: border-box;
			}
		}
		ul,
		ol {
			padding-left: 20px;
			margin: 10px 0;
			li {
				margin: 8px 0;
				font-size: 14px;
				a {
					color: $textDarkColor;
					text-decoration: underline;
					&:hover {
						color: $themePrimaryColor;
					}
				}
				span {
					color: $textColor;
				}
			}
		}
		p {
			font-size: 12px;
		}
	}
}
