@import '../base/base.scss';

$border-color: #cacaca;
$row-background: #f5f5f5;

.fullWidth {
	width: 100%;
}

.reducedWidth {
	width: calc(100% - 35px);
}

.groupResultsWrapper {
	background: #fff;
	border-radius: 15px;
	min-height: calc(100vh - 110px);
	overflow: hidden;
	position: relative;
	h1 {
		color: $headingcolor;
		font-size: 20px;
		margin: 20px 20px 30px;
	}

	p {
		padding: 0 20px;
	}

	select {
		height: 30px;
		margin: 20px;
		border-radius: 5px;
		color: $textDarkColor;
		border-color: $textDarkColor;
	}

	.studentWrap {
		background: white;
	}

	.row {
		width: auto;
		width: max-content;
		&:not(.headerRow) {
			@media print {
				height: 56px;
			}
		}
		.studentColumn {
			width: 15vw;
			min-width: 230px;
			a {
				height: 100%;
				span {
					transition: all 0.2s linear;
				}
				&:hover span {
					color: $themePrimaryColor;
				}
			}
			@media print {
				width: 160px;
				min-width: initial;
			}
		}
	}
}

.pageContainer {
	display: block;
	.overflowController {
		overflow: auto;
	}
	.simplebar-wrapper {
		padding-bottom: 40px;
		@media print {
			padding-bottom: 0;
			height: auto !important;
			overflow: initial !important;
		}
	}
	div[data-simplebar],
	.simplebar-mask,
	.simplebar-offset,
	.simplebar-content-wrapper,
	.simplebar-content {
		@media print {
			padding: 0;
			height: auto !important;
			max-height: initial !important;
			overflow: initial !important;
			position: relative !important;
		}
	}
	.simplebar-placeholder,
	.simplebar-height-auto-observer-wrapper {
		@media print {
			display: none !important;
		}
	}
	.simplebar-track {
		@media print {
			display: none;
		}
		&.simplebar-horizontal {
			left: 50%;
			width: 85%;
			height: 4px;
			bottom: 12px;
			overflow: initial;
			border-radius: 10px;
			background-color: #eee;
			transform: translateX(-50%);
		}
		&.simplebar-horizontal .simplebar-visible,
		&.simplebar-vertical .simplebar-visible {
			top: -1px;
			height: 6px;
			&:before {
				opacity: 0.8;
			}
		}
	}
	.row {
		display: flex;
		&:nth-child(even) {
			.studentColumn {
				.studentWrap {
					background: $row-background;
				}
			}
			.tableColumn {
				background: $row-background;
			}
		}
		.studentColumn {
			position: sticky;
			left: 0;
			top: 0;
			z-index: 3;
			background: white;
			flex-shrink: 0;
			border-right: 1px solid $border-color;
			.studentWrap {
				margin: 0;
				height: 100%;
				border-radius: 0;
				img:not(.icon) {
					background-color: transparent;
					box-sizing: border-box;
					padding: 4px;
				}
			}
		}
		.tableColumn {
			display: flex;
			flex: 1;
		}
		.groupColumn {
			display: flex;
			min-width: 90px;
			flex-direction: column;
			border-right: 1px solid $border-color;
			@media print {
				min-width: 38px;
			}
			.goalRow {
				padding: 10px;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				position: relative;
				@media print {
					padding: 0px;
				}
				.goalName {
					top: 10px;
					font-size: 12px;
					text-align: center;
					position: absolute;
					color: $headingcolor;
					box-sizing: border-box;
					@media print {
						top: 0;
						padding: 0;
						position: relative;
						writing-mode: vertical-rl;
						transform: rotate(-180deg);
					}
				}
				img {
					max-width: 55px;
					margin: 40px auto 0px;
					@media print {
						width: 35px;
						margin: 20px auto;
					}
				}
			}
			.row {
				flex: 1;
				justify-content: center;
				.stepButton {
					z-index: 2;
					opacity: 0;
					cursor: pointer;
					visibility: hidden;
					position: absolute;
					transition: all 0.2s linear;
					svg {
						width: 12px;
						transition: all 0.2s linear;
					}
					&:hover {
						svg {
							fill: $themePrimaryColor;
						}
					}
					&:disabled {
						svg {
							fill: gray;
						}
						&:hover {
							svg {
								fill: gray;
							}
						}
					}
					&.nextArrow {
						right: 6px;
					}
					&.prevArrow {
						left: 6px;
						transform: rotateY(180deg);
					}
				}
				.itemButton {
					z-index: 1;
					width: 100%;
					height: 100%;
					cursor: pointer;
				}
				.step {
					width: 90px;
					@media print {
						width: 38px;
					}
					display: flex;
					position: relative;
					text-align: center;
					align-items: center;
					flex-direction: column;
					justify-content: center;
					box-sizing: border-box;
					&:not(:last-child) {
						border-right: 1px solid rgba($border-color, 0.4);
					}
					&.cursor {
						cursor: pointer;
					}
					&.behind {
						background: rgba($orangeColor, 0.3);
					}
					&.ahead {
						background: rgba($blueColor, 0.3);
					}
					&.done {
						background: rgba($generic, 0.3);
					}
					&.on-track {
						background: rgba($greenColor, 0.3);
					}
					&:hover {
						.stepButton {
							opacity: 1;
							visibility: visible;
						}
					}
				}
				.lessonName {
					font-size: 10px;
					padding: 0 5px;
					margin: 8px 0;
					@media print {
						font-size: 12px;
						line-height: 10px;
						transform: rotate(-90deg);
					}
				}
				.currentStep {
					display: flex;
					font-size: 12px;
					font-weight: 600;
					position: relative;
					align-items: center;
					color: $textDarkColor;
					justify-content: center;
					b {
						display: block;
						transform: scale(2.5);
					}
					.iconWrap {
						top: 0;
						display: flex;
						position: absolute;
						transform: translateX(18px);
						svg {
							width: 10px;
							height: 10px;
						}
						@media print {
							transform: translateX(10px);
							svg {
								width: 8px;
								height: 8px;
							}
						}
						&.eyeIcon {
							transform: translateX(-18px);
							@media print {
								transform: translateX(-10px);
							}
						}
					}
					&.addboxStyle {
						display: flex;
						flex: 1;
						width: 100%;
						height: 100%;
						justify-content: center;
						align-items: center;
					}
					&.darkBox {
						background: lighten($darkBgColor, 30%);
					}
					&.lightBox {
						background: lighten($darkBgColor, 60%);
					}
				}
			}
			&:last-child {
				border: 0;
			}
		}
		&.headerRow {
			background: white;
			@media print {
				.step {
					height: 120px;
				}
			}
			.studentColumn {
				.studentWrap {
					background: white;
				}
			}
			.tableColumn {
				background: white;
			}
		}
	}
}

.popupWrapper {
	.circleLoader {
		margin-right: 10px;
		margin-top: 3px;
	}
}

.deleteWrapper,
.resetPassWrapper {
	display: none;
	visibility: hidden;
	opacity: 0;
	p {
		font-size: 16px;
		line-height: 1.6;
		margin-bottom: 15px;
	}
	.success {
		margin-top: 20px;
		margin-bottom: 0;
		color: $themePrimaryColor;
	}
}

.refreshWrapper {
	left: 0;
	right: 0;
	top: 50%;
	z-index: 2;
	margin: auto;
	height: 100px;
	position: fixed;
	max-width: 500px;
	border-radius: 25px;
	background: white;
	border: 20px solid black;
	transform: translateY(-50%);
	h4 {
		top: 50%;
		padding: 10px;
		font-size: 16px;
		color: black;
		text-align: center;
	}
	.outlineButton {
		left: 0;
		right: 0;
		margin: auto;
		color: #333;
		padding: 0 15px;
		position: absolute;
		border-color: #333;
		background: transparent;
	}
}

.absoluteTab {
	top: 31px;
	position: absolute;
	&.tabsStyle li a {
		font-size: 14px;
		padding: 12px 15px;
	}
}

.rc-slider-disabled {
	background-color: transparent;
}
