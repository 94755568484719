@import '../../base/base.scss';

.academyOverview {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	& * {
		box-sizing: border-box;
	}
	.courseWrapper {
		padding: 12px;
		margin-bottom: 10px;
		border-radius: 15px;
		background: $generic;
		width: calc(50% - 5px);
		.wrapper {
			height: 100%;
			display: flex;
			padding: 12px;
			background: white;
			border-radius: 15px;
			img {
				width: 130px;
				height: 130px;
				margin-right: 10px;
				object-fit: cover;
			}
			.contentSection {
				flex: 1;
				display: flex;
				margin-top: 0px;
				flex-direction: column;
				justify-content: space-between;
			}
			a {
				.headingStyle {
					font-size: 20px;
				}
				&:hover .headingStyle {
					color: $themePrimaryColor;
				}
			}

			.columns {
				display: flex;
				font-size: 14px;
				margin-top: 2px;
				flex-direction: column;
				span {
					margin: 4px 0;
					color: $textDarkColor;
					b {
						min-width: 75px;
						margin-right: 5px;
						vertical-align: top;
						display: inline-block;
					}
				}
			}

			.ratingWrapper {
				display: flex;
				margin-top: 5px;
				align-items: center;
				justify-content: space-between;
				span {
					margin-right: 5px;
				}
				.star-ratings-css {
					padding: 0;
					height: 25px;
					width: 100px;
					margin: 0 auto;
					font-size: 20px;
					position: relative;
					unicode-bidi: bidi-override;
					color: lighten($generic, 20%);
				}
				.star-ratings-css-top {
					top: 0;
					left: 0;
					padding: 0;
					z-index: 1;
					display: block;
					position: absolute;
					overflow: hidden;
					color: darken($generic, 10%);
				}
				.star-ratings-css-bottom {
					padding: 0;
					z-index: 0;
					display: block;
				}
			}
		}
	}
}

.courseIntroduction {
	@extend .academyOverview;
}
