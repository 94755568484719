@import '~css/base/base.scss';

.mediaPageContainer {
	.emptyData {
		margin: 20px 0px 0;
	}

	.mediaPreviewBox {
		width: 25%;
		.aspectContainer {
			padding-top: 56.25%;
			.previewButton {
				top: 50%;
				opacity: 0;
				z-index: 2;
				cursor: pointer;
				position: absolute;
				transition: opacity 0.2s linear;
				transform: translateY(-50%);
				svg {
					fill: $white;
					filter: invert(0.1);
					transition: all 0.2s linear;
				}
				&:hover svg {
					filter: invert(0);
					fill: $themePrimaryColor;
				}
			}
			.loaderPlaceholder {
				top: 50%;
				left: 50%;
				width: 100px;
				height: 100px;
				position: absolute;
				transform: translate(-50%, -50%);
				> div {
					min-height: initial;
				}
			}
		}
		.editButton {
			background: none;
			svg {
				fill: $white;
				filter: invert(0.2);
			}
		}
		&:hover {
			.previewButton {
				opacity: 1;
			}
		}
	}
}
