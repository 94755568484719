$themePrimaryColor: #48b848;
$themeGrayColor: #ececec;

$blueColor: #2db1ff;
$orangeColor: #eb6308;
$greenColor: #43da41;
$yellowColor: #ffbc49;

$lenteSeason: #fec900;
$zomerSeason: #48b848;
$winterSeason: #71529e;
$herfstSeason: #fc932a;

$taal: #e31d19;
$rekenen: $zomerSeason;
$motoriek: $winterSeason;
$grote-motoriek: $yellowColor;

// $motoriek: #00b1ff; // for future tab
$generic: #777;

$lichaam: #FB47A2;
$wereld-om-ons-heen: #1869A1;
$natuur: #83CB6A;
$tijd: #FF4227;
$natuurverschijnselen: #6F5AAE;
$ontdekken: #47CDE5;
$lightbackground: #f9f9f9;

$headingcolor: #333;

$textDarkColor: #333;

$darkBgColor: #222;

$textColor: #666;

$disableTextColor: #999;
$disableBackgroundColor: #ccccc9;
$product: $disableTextColor;

$white: #fff;

$white-gray: #f1f1f1;

$light-gray: #f5f5f5;

$placeholderColor: #f6f6f6;
