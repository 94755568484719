@import '../../base/base.scss';

.parentLessonPopup {
	color: $textDarkColor;
	p {
		margin: 20px 0;
		&:last-child {
			margin-bottom: 0;
		}
	}
	.subAnchors {
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		a,
		button {
			&:not(:last-child) {
				margin-right: 12px;
			}
		}
	}
}

.oudersButton {
	margin-top: 10px;
	margin-right: 10px;
	display: inline-flex;

	button {
		height: 35px;
		cursor: pointer;
		padding: 0 10px;
		position: relative;
		color: $textDarkColor;
		transition: all 0.2s linear;
		border: 1px solid $textDarkColor;
		&:first-child {
			border-top-left-radius: 5px;
			border-bottom-left-radius: 5px;
		}
		&:hover {
			z-index: 2;
			color: $themePrimaryColor;
			border-color: $themePrimaryColor;
		}
	}
}

.ouderStatus {
	display: flex;
	margin-left: -1px;
	align-items: center;
	justify-content: center;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	border-left: 1px solid $textDarkColor;
	svg {
		width: 20px;
		height: 20px;
		transition: all 0.2s linear;
	}
	&:hover {
		svg {
			fill: $themePrimaryColor;
		}
	}
}
