@import '~css/base/base.scss';

.manageParnasys {
	.loaderFixedWrapper {
		top: 0;
		left: 0;
		z-index: 9;
		width: 100%;
		height: 100vh;
		position: fixed;
		background-color: rgba(0, 0, 0, 0.5);
		.loaderContainer {
			display: flex;
			width: 100%;
			max-width: 640px;
			border-radius: 20px;
			flex-direction: column;
			background-color: white;
		}
		p {
			margin-top: 30px;
			text-align: center;
		}
		.loaderWrapper {
			min-height: auto;
			background: transparent;
		}
	}
	.importWrapper {
		margin-top: 30px;
		p,
		li,
		h2,
		strong {
			color: $textDarkColor;
		}
		h2 {
			margin-bottom: 20px;
		}
		p {
			margin: 10px 0;
		}
		strong {
			margin-top: 20px;
			font-weight: 400;
		}
		ul {
			margin: 0;
			li {
				margin: 8px 0;
			}
		}
	}
}
