@import '../base/base.scss';
.rightBar {
	display: flex;
	flex-direction: column;
	.logoColumn {
		.logo {
			width: 250px;
			margin: 12px auto;
		}
	}
	.schoolname {
		color: $textDarkColor;
		padding: 0px 15px;
	}
	.userOptions {
		color: $textDarkColor;
		padding: 10px 15px;
		.user {
			display: flex;
			align-items: center;
			justify-content: space-between;
			& > div {
				display: flex;
				align-items: center;
			}
			img {
				width: 40px;
				height: 40px;
				object-fit: cover;
				border-radius: 5px;
				margin-right: 8px;
			}
			span {
				font-size: 14px;
			}
			button {
				cursor: pointer;
				svg {
					top: 2px;
					position: relative;
					width: 25px;
					fill: $textDarkColor;
					transition: all 0.2s linear;
				}
				&:hover {
					svg {
						fill: $themePrimaryColor;
					}
				}
				&.active {
					svg {
						fill: $blueColor;
					}
				}
			}
		}
		.optionList {
			list-style-type: none;
			padding: 0;
			margin: 0 0;
			height: 0px;
			overflow: hidden;
			li {
				a,
				button {
					width: 100%;
					padding: 10px 0;
					color: $textDarkColor;
					text-align: left;
					font-size: 14px;
					border-bottom: 1px solid rgba($disableTextColor, 0.3);
					transition: color 0.2s linear;
					&:hover {
						color: $themePrimaryColor;
					}
					&.active {
						color: $themePrimaryColor;
					}
				}
				&:last-child {
					a,
					button {
						cursor: pointer;
						border: 0;
					}
				}
			}
		}
	}
}

.groupdropdownWrapper {
	position: relative;
	.row {
		display: flex;
		background: $white-gray;
	}
	.groupDropDown {
		cursor: pointer;
		border: 0;
		border-radius: 0;
		width: 100%;
		padding: 15px;
		font-size: 16px;
		font-weight: 600;
		margin-right: 20px;
		color: $textDarkColor;
		background: $white-gray;
		-webkit-appearance: none;
	}
	.arrowIcon {
		position: absolute;
		height: 10px;
		width: 10px;
		top: 20px;
		right: 60px;
		z-index: 2;
		transform: rotate(180deg);
		pointer-events: none;
	}
	button {
		cursor: pointer;
		margin-right: 15px;
		svg {
			top: 2px;
			position: relative;
			width: 25px;
			fill: $textDarkColor;
			transition: all 0.2s linear;
		}
		&:hover {
			svg {
				fill: $themePrimaryColor;
			}
		}
		&.active {
			svg {
				fill: $blueColor;
			}
		}
	}
	.groupLinks {
		margin: 0 0 6px;
		padding: 0 15px;
		list-style-type: none;
		height: 0;
		overflow: hidden;
		li {
			a {
				width: 100%;
				padding: 10px 0;
				color: $textDarkColor;
				text-align: left;
				font-size: 14px;
				border-bottom: 1px solid rgba($disableTextColor, 0.3);
				transition: color 0.2s linear;
				&:hover,
				&.active {
					color: $themePrimaryColor;
				}
			}
			&:last-child {
				a {
					border: 0;
				}
			}
		}
	}
}

.studentList {
	padding: 0 5px;
	h4 {
		font-size: 16px;
		color: $textDarkColor;
	}
	.accordian {
		max-height: 75px;
		overflow: hidden;
		transition: max-height 0.4s linear;
		&.expand {
			max-height: 2000px;
			.studentParnt span {
				font-weight: 400;
			}
		}
	}
	.studentParnt {
		img {
			width: 60px;
			height: 60px;
		}
	}
}

.stdReportsWrapp {
	margin-top: 30px;
	padding: 0 15px;
	a {
		margin: 15px 0;
		font-size: 14px;
		color: $textDarkColor;
		text-decoration: underline;
		transition: all 0.2s linear;
		&:hover {
			color: $themePrimaryColor;
		}
	}
	.photoWrapper {
		width: 100%;
		cursor: pointer;
		margin-top: 10px;
		.flexCenter {
			pointer-events: none;
			width: calc(33.33% - 5px);
		}
		.aspectContainer {
			padding-top: 56.25%;
			img,
			iframe,
			video {
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				position: absolute;
				border-radius: 5px;
			}
		}
	}
}

.teacherList {
	margin-top: 30px;
	padding: 0 15px;
	.teacher {
		display: flex;
		margin: 15px 0;
		align-items: center;
		img {
			width: 60px;
			margin-right: 10px;
			border-radius: 10px;
			box-sizing: border-box;
		}
		span {
			color: $textDarkColor;
		}
	}
}
