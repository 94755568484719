@import "../base/base.scss";

.changePassWrapper {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  align-items: flex-start;
  label {
    font-weight: bold;
    margin: 20px 0px 10px;
    color: $textDarkColor;
  }
  input {
    outline: 0;
    padding: 10px;
    width: 100%;
    max-width: 600px;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  .error {
    color: red;
    margin-top: 20px;
  }
  .success {
    color: green;
    margin-top: 20px;
  }
  .row {
    margin-top: 20px;
    display: flex;
  }
  button {
    margin-right: 20px;
  }
}
