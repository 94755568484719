@import '../base/base.scss';

.headerWrapper {
	display: flex;
	background: white;
	.navColumn {
		display: flex;
		flex: 1 1 auto;
		padding-top: 15px;
		align-items: flex-end;
		justify-content: space-between;

		.seasonTab {
			padding: 0;
			margin: 0 0 0;
			list-style-type: none;
			display: flex;
			margin-left: 40px;
			li {
				@include border-top-radius(15px);
				align-items: center;
				justify-content: center;
				display: flex;
				a {
					color: white;
					font-size: 14px;
					padding: 10px 20px;
				}
			}
			.kalendarTab {
				opacity: 1;
				background: $themeGrayColor;
			}
		}

		.navMenu {
			display: flex;
			align-items: center;
			margin-right: 15px;
			position: relative;
			.menu {
				display: flex;
				margin-right: 35px;
				a {
					color: $textDarkColor;
					font-size: 14px;
					padding: 10px 8px;
					opacity: 0.7;
					@include border-top-radius(15px);
					transition: all 0.2s linear;
					&:last-child {
						margin-right: 0;
					}
					&:hover {
						opacity: 1;
					}
					&.activeLink {
						opacity: 1;
						background: $themeGrayColor;
					}
					&.notify {
						position: relative;
						&:after {
							right: 0;
							top: 5px;
							width: 8px;
							height: 8px;
							content: '';
							position: absolute;
							border-radius: 10px;
							background-color: $taal;
							-webkit-animation: flickerAnimation 1s infinite;
							-moz-animation: flickerAnimation 1s infinite;
							-o-animation: flickerAnimation 1s infinite;
							animation: flickerAnimation 1s infinite;
						}
					}
				}
			}
			.searchWrapper {
				right: 0;
				z-index: 2;
				width: 30px;
				bottom: 0;
				position: absolute;
				background: white;
				padding: 12px 0 5px 0;

				input {
					width: 100%;
					padding: 0 10px 5px;
					border: none;
					color: $textDarkColor;
					opacity: 0;
					visibility: hidden;
					border-bottom: 1px solid $darkBgColor;
					@include placeholder {
						color: $textDarkColor;
					}
				}
				.searchSubmit {
					position: absolute;
					z-index: 2;
					right: 10px;
					top: 14px;
					cursor: pointer;
					svg {
						height: 15px;
						fill: $textColor;
					}
				}
				.searchDropdown {
					left: 0;
					top: 34px;
					width: 100%;
					padding: 15px;
					display: flex;
					overflow: auto;
					min-height: 100px;
					max-height: 250px;
					position: absolute;
					background: white;
					box-sizing: border-box;
					align-items: space-between;
					-webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.15);
					-moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.15);
					box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.15);
					-webkit-border-bottom-right-radius: 5px;
					-webkit-border-bottom-left-radius: 5px;
					-moz-border-radius-bottomright: 5px;
					-moz-border-radius-bottomleft: 5px;
					border-bottom-right-radius: 5px;
					border-bottom-left-radius: 5px;

					&::-webkit-scrollbar {
						-webkit-appearance: none;
						width: 7px;
					}
					&::-webkit-scrollbar-thumb {
						border-radius: 4px;
						background-color: rgba(0, 0, 0, 0.5);
						-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
					}
					&::-webkit-scrollbar-track {
						background-color: #f5f5f5;
						border-radius: 8px;
					}
					.title {
						color: $textDarkColor;
					}
					p {
						font-size: 14px;
						color: $textDarkColor;
					}
					& > div {
						display: flex;
						flex: 1;
						flex-direction: column;
						strong {
							margin-bottom: 10px;
						}
						&:not(:first-child) {
							ul {
								padding-left: 15px;
								margin-left: -15px;
								border-left: 1px solid #eee;
							}
						}
					}
					ul {
						flex: 1;
						margin: 0;
						padding: 0;
						display: flex;
						flex-direction: column;
						list-style-type: none;
						&:empty {
							display: none;
						}
						li {
							a {
								padding: 5px 0;
								color: $textDarkColor;
								transition: all 0.2s linear;
								font-size: 14px;
								display: inline-block;
								vertical-align: top;
								&:hover {
									color: $themePrimaryColor;
								}
							}
							&:not(:last-child) {
								margin-bottom: 3px;
							}
						}
					}
				}
			}
		}
	}
}
