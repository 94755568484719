@import '../base/base.scss';

// .closePopup {
//   position: absolute;
//   top: 15px;
//   right: 15px;
//   z-index: 2;
//   cursor: pointer;
//   background: transparent;
//   svg {
//     transition: all 0.2s linear;
//   }
//   &:hover {
//     svg {
//       fill: $themePrimaryColor;
//     }
//   }
// }

.studentPrintWrapper {
	position: relative;
	.headingStyle {
		display: block;
		width: 100%;
	}
}

.closePopup {
	position: absolute;
	top: -30px;
	right: 0px;
	z-index: 2;
	cursor: pointer;
	background: transparent;
	svg {
		transition: all 0.2s linear;
		fill: white;
	}
	&:hover {
		svg {
			fill: $themePrimaryColor;
		}
	}
}

.studentListWrapper {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	margin: 0 -10px;
	.noContent {
		margin: 20px 10px;
	}
	.studentBox {
		width: 20%;
		display: flex;
		padding: 0 10px;
		margin-top: 20px;
		position: relative;
		align-items: center;
		box-sizing: border-box;
		flex-direction: column;
		img,
		video,
		iframe {
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			background: #fff;
			position: absolute;
		}
		strong {
			color: $textDarkColor;
			margin: 10px 0 4px;
		}
		span {
			font-size: 12px;
		}
		.editButton {
			background: rgba(white, 0.8);
			width: 40px;
			height: 40px;
			position: absolute;
			top: 0;
			right: 10px;
			z-index: 2;
			opacity: 0;
			visibility: hidden;
			transition: all 0.2s linear;
			cursor: pointer;
			svg {
				width: 15px;
				height: 15px;
				fill: $textDarkColor;
				transition: all 0.2s linear;
			}
			&:hover {
				svg {
					fill: $themePrimaryColor;
				}
			}
		}
		.addStudent {
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			cursor: pointer;
			position: absolute;
			background: #f5f5f5;
			svg {
				width: 50px;
				height: 50px;
				fill: lighten($textDarkColor, 20%);
				transition: all 0.2s linear;
			}
			&:hover {
				svg {
					fill: $themePrimaryColor;
				}
			}
		}
		&.showIcon,
		&:hover {
			.editButton {
				opacity: 1;
				visibility: visible;
			}
		}
	}
	@media print {
		padding: 0 20px;
	}
}

.printContainer {
	@media print {
		padding: 20px 0px;
		.headingStyle {
			margin-left: 20px;
		}
	}
}

.studentPopupWrapper {
	.headingStyle {
		margin-bottom: 15px;
	}
	label {
		margin-bottom: 10px;
		font-weight: bold;
		color: $textDarkColor;
	}
	label.small {
		font-weight: normal;
		font-size: 12px;
	}
	.inputField {
		@extend %inputFields;
	}
	.row {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		position: relative;
		input {
			margin-top: 0px;
		}
		.groupSelectWrap {
			position: absolute;
			margin-top: 40px;
		}
		select {
			height: 30px;
			color: #333;
			min-width: 135px;
			margin-bottom: 20px;
			border-color: #333;
		}
	}
	.selectboxWrapper {
		display: flex;
		select {
			height: 30px;
			color: #333;
			cursor: pointer;
			min-width: 135px;
			margin-right: 10px;
			margin-bottom: 20px;
			border-color: #333;
			&.disable {
				cursor: not-allowed;
				color: $disableTextColor;
				border-color: $disableTextColor;
			}
		}
		button {
			padding: 0;
			width: 35px;
			height: 30px;
			display: flex;
			align-items: center;
			justify-content: center;
			svg {
				width: 10px;
				height: 10px;
				fill: white;
			}
			&:not(.disable):hover {
				svg {
					fill: $themePrimaryColor;
				}
			}
			&.disable {
				cursor: not-allowed;
				background: $disableTextColor;
				border-color: $disableTextColor;
			}
		}
	}
	.kidsRow {
		display: flex;
		flex-wrap: wrap;
		margin: 0 -5px;
		.stdWrp {
			width: 50%;
			padding: 0 5px;
			position: relative;
			box-sizing: border-box;
		}
		button {
			top: -5px;
			right: 0;
			z-index: 2;
			width: 15px;
			height: 15px;
			display: flex;
			cursor: pointer;
			position: absolute;
			border-radius: 50%;
			background: white;
			align-items: center;
			justify-content: center;
			box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
			svg {
				width: 7px;
			}
			&:hover {
				fill: $themePrimaryColor;
			}
		}
	}
	.profilePicture {
		width: 150px;
		height: 150px;
		margin-top: -30px;
		position: relative;
		object-fit: cover;
	}
	.error {
		color: red;
		font-size: 14px;
		margin: 10px 0;
	}
	.buttonsWrapper {
		margin-top: 20px;
	}
	.warning {
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 15px;
	}
}
