@import '~css/base/base.scss';

$border-color: #cacaca;
$background-color: #f5f5f5;

.titleCase {
	text-transform: capitalize;
	margin-bottom: 0 !important;
}

.behandelplanContainer {
	display: flex;
	padding: 20px;
	margin-right: -15px;
	.nodata {
		padding: 0;
	}
	.column {
		margin-bottom: 20px;
		width: calc(100% - 15px);
		.stepContainer {
			padding: 5px;
			margin-top: 5px;
			border-radius: 10px;
			background-color: $white;
			&:not(:last-child) {
				margin-bottom: 5px;
			}
			.stepWrapper {
				align-items: center;
			}
			.studentStep {
				width: 50px;
				height: 50px;
				flex: 0 0 50px;
				font-weight: bold;
				line-height: 50px;
				margin-right: 10px;
				text-align: center;
				border-radius: 10px;
				color: $textDarkColor;
				background-color: $light-gray;
				b {
					font-size: 30px;
				}
			}
			.studentStepDescr {
				font-size: 14px;
				font-weight: bold;
				padding-right: 5px;
				color: $textDarkColor;
			}
		}
		.studentRowContainer {
			width: 100%;
			margin-top: 5px;
			cursor: pointer;
			text-align: left;
			position: relative;
			border-radius: 10px;
			background-color: $light-gray;
			.latestComments {
				font-size: 14px;
				padding: 5px 10px 8px;
			}
		}
		.studentWrap {
			padding: 5px;
			margin-bottom: 0;
			border-radius: 10px;
			background-color: $light-gray;

			img {
				width: 45px;
				height: 45px;
				margin: 0 10px 0 0;
			}
			.name {
				font-size: 15px;
				font-weight: bold;
			}
			.age {
				margin-top: 5px;
				font-size: 14px;
			}
		}
		.lessonWrap {
			padding: 5px;
			border-radius: 10px;
			background: $background-color;
			section {
				padding: 10px;
				font-size: 14px;
				background: #fff;
				border-radius: 5px;
				.lessonRow {
					display: flex;
					color: $textDarkColor;
					align-items: flex-start;
					.lessonColumn {
						margin-left: 10px;
						p {
							padding: 0;
							line-height: 20px;
						}
						h3 {
							font-size: 16px;
							margin-bottom: 10px;
						}
						strong {
							margin-bottom: 10px;
							span {
								opacity: 0.6;
								margin-left: 10px;
							}
						}
					}
				}
			}
		}
	}
}

.verticalTab {
	width: 36px;
	margin: 80px 0;
	padding: 0;
	display: flex;
	align-items: flex-start;
	flex-direction: row-reverse;
	list-style-type: none;
	position: sticky;
	top: 40px;
	writing-mode: vertical-lr;
	transform: rotate(-180deg);
	li {
		opacity: 0.6;
		display: flex;
		align-items: center;
		background: $white;
		justify-content: center;
		@include border-right-radius(15px);
		a {
			padding: 26px 8px;
			color: $white;
		}
		&.active {
			opacity: 1;
		}
	}
}
