@import '../../base/base.scss';

.commentSection {
	margin: 20px 0 0;
	.cmntImg {
		width: 60px;
		height: 60px;
		object-fit: cover;
		border-radius: 5px;
		margin-right: 10px;
	}
	.commentForm {
		margin-top: 30px;
	}
	.comment {
		display: flex;
		margin: 20px 0;
		flex-wrap: wrap;
		.content {
			padding: 10px;
			border-radius: 10px;
			background: $white-gray;
			width: calc(100% - 90px);
			.media {
				width: 50%;
				margin: 8px auto;
				min-height: 250px;
				img {
					object-fit: cover;
					width: 100% !important;
					height: 100% !important;
				}
			}
			p {
				margin-top: 0px;
				font-size: 16px;
				margin-bottom: 5px;
				color: $textDarkColor;
			}
			.row {
				margin-bottom: 5px;
				a {
					font-size: 14px;
					margin-right: 10px;
				}
			}
			.cmntTime {
				font-size: 12px;
			}
			img {
				width: 60px;
				height: 60px;
				margin-right: 10px;
			}
		}
	}
	.repliedComment {
		flex-direction: row-reverse;
		img {
			margin: 0 0 0 10px;
		}
	}
	.commentForm {
		h4 {
			margin: 10px 0;
		}
		.flexBlock {
			flex-wrap: wrap;
		}
		.commentWrapper {
			display: flex;
			.quill {
				padding: 0;
				height: 100px;
				border-radius: 5px;
				width: calc(100% - 140px);
				border: 1px solid lighten($generic, 40%);
				p {
					margin: 0;
				}
				.ql-toolbar {
					display: none;
				}
				.ql-container.ql-snow {
					border: none;
				}
				.ql-editor.ql-blank {
					&:before {
						top: 16px;
					}
				}
			}
		}
		textarea {
			height: 100px;
			padding: 10px;
			border-radius: 5px;
			width: calc(100% - 70px);
			border: 1px solid lighten($generic, 40%);
		}
		.attachmentRow {
			display: flex;
			padding-left: 70px;
			flex-direction: column;
			.row {
				margin-top: 20px;
				align-items: center;
			}
		}
		.inputFeild {
			outline: 0;
			width: 100%;
			padding: 10px;
			max-width: 300px;
			border-radius: 5px;
			margin-right: 15px;
			border: 1px solid #ddd;
		}
		.addbutton {
			height: 39px;
			font-size: 30px;
			margin-top: 10px;
			width: max-content;
			&:disabled {
				color: white;
				background: gray;
				&:hover {
					color: white;
					background: gray;
				}
			}
		}
		.rowWrapper {
			display: flex;
			margin-top: 20px;
			margin-bottom: 15px;
			align-items: center;
			justify-content: space-between;
		}
	}
	.error {
		color: red;
	}
}
