@import 'src/css/base/base.scss';

.productPopup {
	color: $textDarkColor;
	&.popupWrapper {
		opacity: 1;
		visibility: visible;
	}
	.closePopup {
		top: 20px;
		right: 20px;
		svg {
			fill: $textDarkColor;
		}
	}
	.popup {
		.loaderWrapper {
			min-height: 204px;
		}
	}
	.headingStyle,
	p {
		margin-bottom: 15px;
	}
	.checkBoxWrapper {
		display: flex;
		input {
			margin: 2px 10px 0 0;
		}
	}
	.errorMsg {
		color: #e31d19;
		margin-bottom: 15px;
	}
}

.customerDetails {
	margin-bottom: 15px;
	.buttonsWrapper {
		display: flex;
		button {
			cursor: pointer;
			font-size: 16px;
			font-weight: bold;
			margin-left: 15px;
			margin-bottom: 8px;
			color: $textDarkColor;
			text-decoration: underline;
			transition: color 0.2s linear;
			&:hover {
				color: $themePrimaryColor;
			}
		}
	}
	strong,
	p {
		margin-bottom: 8px;
	}
	span {
		display: block;
		margin-bottom: 4px;
	}
	label {
		font-size: 14px;
		font-weight: bold;
		margin-bottom: 8px;
		color: $textDarkColor;
	}
	.inputField {
		margin-bottom: 14px !important;
		&:last-of-type {
			margin-bottom: 0 !important;
		}
	}
}
